import { useState } from 'react'
import { HiOutlineChevronDown, HiOutlineChevronUp } from 'react-icons/hi'

import { DashboardContentProps } from '@elements/dashboard/Dashboard.types'
import { Button } from '@elements/index'

export const TableFilterContent: React.FC<DashboardContentProps> = (props) => {
  const { title, children, hasAccordion } = props

  const [isOpen, setIsOpen] = useState(false)

  function handleToggle() {
    setIsOpen((isOpen) => !isOpen)
  }

  // const accordion = useDisclosure(false)
  // const [element, setElement] = useState<HTMLDivElement | null>(null)

  // const [height, setHeight] = useState<number>(0)

  // const handleSize = useCallback(() => {
  //   setHeight(element?.offsetHeight || 0)
  // }, [element?.offsetHeight, element?.offsetWidth])

  // useEventListener('resize', handleSize)

  // useIsomorphicLayoutEffect(() => {
  //   handleSize()
  // }, [element?.offsetHeight, element?.offsetWidth])

  // useEffect(() => {
  //   if (!hasAccordion) return
  //   if (element?.style) {
  //     element.style.height = accordion.isOpen ? '0px' : `${height}px`
  //     element.style.overflow = accordion.isOpen ? 'hidden' : 'auto'
  //   }
  // }, [accordion.isOpen])

  return (
    <div className='grid w-full grid-cols-[auto_36px] items-center gap-x-6 rounded-lg border border-gray-200 bg-white p-4'>
      <h1 className='text-base font-bold tracking-wider'>{title}</h1>
      {hasAccordion ? (
        <Button
          type='button'
          className='icon-only'
          variant='outline'
          onClick={handleToggle}
        >
          {isOpen ? (
            <HiOutlineChevronUp className='h-4 w-4' />
          ) : (
            <HiOutlineChevronDown className='h-4 w-4' />
          )}
        </Button>
      ) : null}

      {isOpen && (
        <div className='mt-3 transition-all duration-300'>{children}</div>
      )}
    </div>
  )
}
