import clsx from 'clsx'

import { getProperty } from '@utils/base'
import { createMemoClass } from '@utils/styles'

import { InputSize, InputSizeMap } from '@forms/index'

import {
  InputAddonPlacement,
  InputAddonPlacementMap,
  InputAddonProps,
} from './InputAddon.types'

const sizes: InputSizeMap<string> = {
  xs: 'xs',
  sm: 'sm',
  md: 'md',
  lg: 'lg',
  xl: 'xl',
}

const placements: InputAddonPlacementMap<string> = {
  left: 'left',
  right: 'right',
}

export default createMemoClass((props: InputAddonProps) => {
  return clsx(
    'input-addon',
    getProperty(sizes, props?.size || InputSize.md),
    getProperty(placements, props?.placement || InputAddonPlacement.left),
    props.disabled && 'disabled',
    props.className
  )
})
