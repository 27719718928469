import clsx from 'clsx'

import { getProperty } from '@utils/base'
import { createMemoClass } from '@utils/styles'

import {
  BadgeProps,
  BadgeSize,
  BadgeSizeMap,
  BadgeVariant,
  BadgeVariantMap,
} from './Badge.types'

const sizes: BadgeSizeMap<string> = {
  md: 'md',
  lg: 'lg',
}

const variants: BadgeVariantMap<string> = {
  default: 'default',
  defaultDark: 'default-dark',
  success: 'success',
  successDark: 'success-dark',
  danger: 'danger',
  dangerDark: 'danger-dark',
  warning: 'warning',
  warningDark: 'warning-dark',
  info: 'info',
  infoDark: 'info-dark',
  infoFill: 'info-fill',
  successFill: 'success-fill',
  lightWarning: 'light-warning',
  error: 'error',
}

export default createMemoClass((props: BadgeProps) => {
  return clsx(
    'badge',
    getProperty(sizes, props?.size || BadgeSize.md),
    getProperty(variants, props?.variant || BadgeVariant.default),
    props?.className
  )
})
