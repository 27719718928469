import { useEffect, useState } from 'react'

import notification from '@stores/notification'

import { Modal, ModalBody, ModalHeader } from '@elements/index'

import { FilePreviewErrorType, FilePreviewProps } from './FilePreview.types'
import FilePreviewError from './FilePreviewError'
import { useDownloadFile } from '../file-upload/fileService'

const FilePreview: React.FC<FilePreviewProps> = (props) => {
  const { preview, file } = props
  const previewFile = useDownloadFile()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<FilePreviewErrorType>()
  const [data, setData] = useState<string>('')

  const getMimeType = (fileExt: string) => {
    let mimeType
    if (fileExt === 'pdf') {
      mimeType = 'application/pdf'
    } else if (fileExt === 'png') {
      mimeType = 'image/png'
    } else {
      setError({
        type: 'not-supported',
        message: 'File preview tidak mendukung untuk tipe file yang dipilih',
      })
      return
    }

    return mimeType
  }

  const downloadingFile = () => {
    if (!file?.fileUrl) return

    async function download() {
      setIsLoading(true)
      await previewFile
        .mutateAsync({
          id: file.fileUrl as string,
        })
        .then((res) => {
          const newBlob = new Blob([res.data], {
            type: getMimeType(file.fileName.slice(-3)),
          })
          if (newBlob.size !== 44) {
            setData(URL.createObjectURL(newBlob))
            setError(undefined)
          } else {
            setData('')
            setError({ type: 'not-found', message: 'File tidak ditemukan' })
          }
        })
        .catch((err) => {
          setError({
            type: 'not-found',
            message: err.message || 'Terjadi kesalahan',
          })
          notification.error('Failed previewing file')
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
    download()
  }
  useEffect(() => {
    downloadingFile()
  }, [])

  return (
    <Modal
      isOpen={preview.isOpen}
      handler={preview.toggle}
      dismiss={{
        closeButton: true,
        escapeKey: false,
        outsidePress: false,
      }}
      isScrollable={false}
      size='lg'
    >
      <ModalHeader className='!pr-[70px]'>{file.fileName}</ModalHeader>
      <ModalBody className='flex min-h-[300px] '>
        {isLoading && (
          <div className='flex w-full items-center justify-center text-gray-400'>
            Loading File..
          </div>
        )}
        {!isLoading &&
          (error ? (
            <FilePreviewError type={error.type} message={error.message} />
          ) : (
            <div className='w-full overflow-hidden'>
              <object
                data={data}
                aria-label='preview'
                type={getMimeType(file.fileName.slice(-3))}
                width='100%'
                className='-mt-[40px] min-h-[100vh]'
              ></object>
            </div>
          ))}
      </ModalBody>
    </Modal>
  )
}

export default FilePreview
