import axios from 'axios'
import React, { forwardRef, useEffect, useState } from 'react'
import {
  HiOutlineDocumentText,
  HiOutlineDownload,
  HiOutlineEye,
  HiOutlineX,
} from 'react-icons/hi'

import notification from '@stores/notification'

import { useFilePreview } from '@hooks/useFilePreview'

import { getErrorMessage, getMessage } from '@utils/apis'
import { getFileName } from '@utils/components'
import { blobDownload } from '@utils/file'

import FilePreview from '@forms/file-preview/FilePreview'
import { Button } from '@elements/index'

import { useDeleteFile, useDownloadFile, useUploadFile } from './fileService'
import { FileContentProps, FileItem, IUploadFileDTO } from './FileUpload.types'

export const FileContent = forwardRef<HTMLDivElement, FileContentProps>(
  (props, ref) => {
    const { item, disabled, showPreview = true, setFiles, ...rest } = props
    const [progress, setProgress] = useState<number>(0)
    const [isDownloading, setDownloading] = useState<boolean>(false)
    const [isDeleting, setDeleting] = useState<boolean>(false)
    const uploadFile = useUploadFile()
    const downloadFile = useDownloadFile()
    const { filePreview } = useFilePreview()

    const deleteFileData = (id: string) => {
      setFiles((prev) =>
        prev.filter((val) => {
          return val.id !== id
        })
      )
    }

    const deleteFile = useDeleteFile()
    const handleDelete = async (item: FileItem) => {
      if (!item.fileUrl) return deleteFileData(item.id)
      setDeleting(true)
      await deleteFile
        .mutateAsync({
          id: item.fileUrl as string,
        })
        .then((res) => {
          notification.success(getMessage(res))
          deleteFileData(item.id)
        })
        .catch((err) => {
          notification.error(getErrorMessage(err))
          deleteFileData(item.id)
        })
        .finally(() => {
          setDeleting(false)
        })
    }

    const handleUpload = (item: FileItem, content: IUploadFileDTO) => {
      setFiles((prev) =>
        prev.map((val) => {
          if (val.id === item.id) {
            return {
              ...item,
              fileName: content.file_name,
              fileUrl: content.file_url,
            }
          }
          return val
        })
      )
    }

    useEffect(() => {
      const controller = new AbortController()
      if (!disabled && item?.file) {
        uploadFile
          .mutateAsync({
            file: item.file,
            progress: setProgress,
            signal: controller.signal,
          })
          .then((res) => {
            const content = res.data.data
            const fileUrl = decodeURI(content?.file_url || '')
            handleUpload(item, {
              file_name: getFileName(fileUrl),
              file_url: decodeURI(fileUrl),
            })
            notification.success(getMessage(res))
          })
          .catch((err) => {
            handleDelete(item)
            if (!axios.isCancel(err)) {
              notification.error(getErrorMessage(err))
            }
          })
        return () => {
          controller.abort()
        }
      }
    }, [])

    const handleDownloadFile = () => {
      if (!item?.fileUrl) return
      async function download() {
        setDownloading(true)
        await downloadFile
          .mutateAsync({
            id: item.fileUrl as string,
          })
          .then((res) => {
            res.data
              .text()
              .then((rss) => {
                const msg = JSON.parse(rss)
                if (msg && msg.message.includes('not found')) {
                  notification.error('File tidak ditemukan')
                }
              })
              .catch(() => {
                blobDownload(res.data, item.fileName)
                notification.success('Successfully download file')
              })
          })
          .catch(() => {
            notification.error('Failed download file')
          })
          .finally(() => {
            setDownloading(false)
          })
      }
      download()
    }

    const handlePreviewFile = () => {
      filePreview.open()
    }

    return (
      <div className='file-upload active relative' ref={ref} {...rest}>
        {!disabled && (
          <Button
            type='button'
            variant='solid'
            className='close'
            isLoading={isDeleting}
            onClick={() => handleDelete(item)}
          >
            <HiOutlineX className='h-5 w-5 text-gray-800' />
          </Button>
        )}
        <div className='flex w-full flex-col items-center justify-center pb-6 pt-5'>
          <HiOutlineDocumentText className='mb-3 h-10 w-10' />
          <div className='mb-2 flex text-sm'>
            <div className='max-w-[200px] truncate'>{item.fileName}</div>
          </div>
          {!disabled && item?.file && (
            <div className='h-1.5 w-full max-w-[85%] rounded-full bg-gray-200 text-center'>
              <div
                className='h-1.5 rounded-full bg-red-500'
                style={{
                  width: `${progress}%`,
                }}
              />
              <span className='mt-1 text-sm'>
                {progress < 100 ? `Uploading ${progress}%` : 'Done'}
              </span>
            </div>
          )}
          <div className='flex gap-2'>
            {disabled && (
              <>
                <Button
                  type='button'
                  variant='outline'
                  size='sm'
                  className='icon-only'
                  onClick={handleDownloadFile}
                  isLoading={isDownloading}
                >
                  <HiOutlineDownload className='h-5 w-5 text-gray-800' />
                </Button>
                {showPreview && (
                  <Button
                    type='button'
                    variant='outline'
                    size='sm'
                    className='icon-only'
                    onClick={handlePreviewFile}
                  >
                    <HiOutlineEye className='h-5 w-5 text-gray-800' />
                  </Button>
                )}
              </>
            )}
          </div>
        </div>
        {filePreview.isOpen && (
          <FilePreview preview={filePreview} file={item} />
        )}
      </div>
    )
  }
)

FileContent.displayName = 'FileContent'
