import clsx from 'clsx'
import React, { forwardRef, useState } from 'react'
import { HiOutlineChevronDown } from 'react-icons/hi'
import { Link } from 'react-router-dom'

import { useAuth } from '@stores/auth'

import { removeStoredLastCurrentPage } from '@utils/base'

import { NavigationItemSubMenuProps } from './Navigation.types'

export const NavigationSubMenu = forwardRef<
  HTMLDivElement,
  NavigationItemSubMenuProps
>((props, ref) => {
  const { contentData, className, collapsed, menuIndex, ...rest } = props
  const [auth] = useAuth()
  const contentRef = React.useRef<HTMLUListElement>(null)
  const [mounted, setMounted] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  let timer: NodeJS.Timer
  const duration = 300

  const handleSubmenuClicked = () => {
    if (collapsed) {
      setIsOpen((prev) => !prev)
    }
  }

  React.useEffect(() => {
    clearTimeout(timer)
    if (mounted) {
      if (isOpen) {
        const target = contentRef?.current
        if (target) {
          target.style.display = 'block'
          target.style.overflow = 'hidden'
          target.style.height = 'auto'
          const height = target.offsetHeight
          target.style.height = '0px'
          target.offsetHeight
          target.style.height = `${height}px`

          timer = setTimeout(() => {
            target.style.overflow = 'auto'
            target.style.height = 'auto'
          }, duration)
        }
      } else {
        const target = contentRef?.current
        if (target) {
          target.style.overflow = 'hidden'
          target.style.height = `${target.offsetHeight}px`
          target.offsetHeight
          target.style.height = '0px'

          timer = setTimeout(() => {
            target.style.overflow = 'auto'
            target.style.display = 'none'
          }, duration)
        }
      }
    }
    return () => clearTimeout(timer)
  }, [isOpen, contentRef])

  React.useEffect(() => {
    if (!collapsed) {
      setIsOpen(false)
    }
    setIsOpen(false)
  }, [collapsed])

  React.useEffect(() => {
    setMounted(true)
    setIsOpen(contentData?.isActive || false)
  }, [])

  return (
    <div
      ref={ref}
      className={clsx(className, contentData?.isActive && 'active-child')}
      {...rest}
    >
      <div className='group relative'>
        <button
          type='button'
          onClick={handleSubmenuClicked}
          aria-hidden='true'
          className='menu'
        >
          <div className='flex'>
            {contentData?.icon && <contentData.icon className='mr-2 h-6 w-6' />}
            <span
              className={clsx(
                collapsed
                  ? 'opacity-100'
                  : 'w-0 overflow-hidden opacity-0 group-hover:w-auto',
                'whitespace-nowrap'
              )}
            >
              {contentData.title}
            </span>
          </div>
          <HiOutlineChevronDown
            className={clsx(
              'h-5 w-5 transition-transform duration-300',
              isOpen && 'rotate-180'
            )}
          />
        </button>
        {!collapsed && (
          <div
            className={clsx(
              menuIndex > 8 ? 'bottom-0' : 'top-0',
              'dropdown-menu absolute left-[120%]  z-50 hidden h-auto group-hover:block'
            )}
          >
            <ul className='mini-scrollbar max-h-[calc(100vh_-_120px)] min-w-[12rem] overflow-y-auto bg-white shadow-md'>
              {contentData?.children &&
                contentData?.children.map((val, idx) => {
                  // !FIXME logic access
                  if (
                    val.access?.[idx] === '' ||
                    (val.access && !auth?.navigation.includes(val.access[0]))
                  )
                    return ''
                  return (
                    <li key={val.to}>
                      <Link
                        to={val.to}
                        onClick={() => removeStoredLastCurrentPage()}
                        className={clsx(
                          'menu',
                          val?.isActive && 'active',
                          'bg-blue-50 !pl-4'
                        )}
                        tabIndex={isOpen ? 0 : -1}
                      >
                        {val?.icon && <val.icon className='mr-2 h-6 w-6' />}
                        <span className='whitespace-nowrap'>{val.title}</span>
                      </Link>
                    </li>
                  )
                })}
            </ul>
          </div>
        )}
      </div>
      <ul
        ref={contentRef}
        className='h-0 overflow-hidden transition-all duration-300'
      >
        {contentData?.children &&
          contentData?.children.map((val, idx) => {
            // !FIXME logic access
            if (
              val.access?.[idx] === '' ||
              (val.access && !auth?.navigation.includes(val.access[0]))
            )
              return ''
            return (
              <li key={val.to}>
                <Link
                  to={val.to}
                  onClick={() => removeStoredLastCurrentPage()}
                  className={clsx('menu', val?.isActive && 'active')}
                  tabIndex={isOpen ? 0 : -1}
                >
                  {val?.icon && <val.icon className='mr-2 h-6 w-6' />}
                  <span>{val.title}</span>
                </Link>
              </li>
            )
          })}
      </ul>
    </div>
  )
})

NavigationSubMenu.displayName = 'NavigationSubMenu'
