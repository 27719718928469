import { DashboardApprovalStatusProps } from '@elements/index'

export const DashboardApprovalStatus: React.FC<DashboardApprovalStatusProps> = (
  props
) => {
  const { isLoading, approval, numLoading } = props
  if (isLoading) {
    return (
      <>
        {Array.from({ length: numLoading || 2 }, (x, i) => i).map((elem) => {
          return (
            <div key={elem} className='animate-pulse space-y-1'>
              <div className='my-4 h-full w-1/2 bg-slate-200 p-3' />
              <div className='my-4 h-full w-full bg-slate-200 p-2' />
              <div className='my-4 h-full w-full bg-slate-200 p-2' />
            </div>
          )
        })}
      </>
    )
  }
  return (
    <>
      {(approval || []).map((val, idx) => {
        return (
          <div key={idx} className='flex flex-col space-y-1 text-gray-600'>
            <h2>{val.title} by </h2>
            <span>
              {val.name} - {val.date}
            </span>
          </div>
        )
      })}
    </>
  )
}
