import clsx from 'clsx'

import { getProperty } from '@utils/base'
import { createMemoClass } from '@utils/styles'

import { AvatarProps, AvatarSize, AvatarSizeMap } from './Avatar.types'

const sizes: AvatarSizeMap<string> = {
  xs: 'xs',
  sm: 'sm',
  md: 'md',
  lg: 'lg',
  xl: 'xl',
}

export default createMemoClass((props: AvatarProps) => {
  return clsx(
    'avatar',
    getProperty(sizes, props?.size || AvatarSize.md),
    props.className
  )
})
