import { HiOutlinePlusSm } from 'react-icons/hi'
import { useNavigate } from 'react-router-dom'

import confirmation from '@stores/confirmation'

import { Button } from '@elements/index'

import { DashboardButtonFormProps } from './Dashboard.types'

export const DashboardButtonForm: React.FC<DashboardButtonFormProps> = (
  props
) => {
  const { pageType, parentPath, formik, allowView, customAction } = props
  const navigate = useNavigate()

  if (pageType.isView && !allowView) return <></>
  return (
    <div className='button-wrapper'>
      <Button
        type='button'
        variant='outline'
        color='danger'
        onClick={() => {
          if (!formik.dirty) {
            navigate(parentPath)
            return
          }
          return confirmation.confirm({
            onConfirm: (close) => {
              close()
              navigate(parentPath)
            },
          })
        }}
      >
        Batal
      </Button>
      {customAction ? (
        customAction({
          formik,
          pageType,
          parentPath,
        })
      ) : (
        <Button
          type='submit'
          variant='solid'
          color='primary'
          leftIcon={
            !pageType.isEdit ? (
              <HiOutlinePlusSm className='mr-2 h-4 w-4' />
            ) : null
          }
          disabled={!(formik.isValid && formik.dirty)}
          isLoading={formik.isSubmitting}
        >
          {pageType.isEdit
            ? 'Simpan'
            : pageType.isDeviasi
            ? 'Deviasi Shipment'
            : pageType.isAmandemen
            ? 'Amandemen SPAL'
            : 'Tambah'}
        </Button>
      )}
    </div>
  )
}
