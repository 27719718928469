import { Route, RouteObject, Routes } from 'react-router-dom'

import AuthProvider from '@providers/Auth'

import { Login } from '@features/auth/components'

import { lazyImport } from '@utils/performance'

const { AuthRoutes } = lazyImport(() => import('@features/auth'), 'AuthRoutes')

export const publicRoutes: RouteObject[] = [
  {
    path: '',
    element: (
      <Routes>
        <Route element={<AuthProvider />}>
          <Route path='' element={<Login />} />
        </Route>
      </Routes>
    ),
  },
  {
    path: '*',
    element: <AuthRoutes />,
  },
]
