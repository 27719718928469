import Axios, { InternalAxiosRequestConfig } from 'axios'

import { API_BASE_URL, storageKey } from '@constants/index'

import { storage } from '@utils/storage'

function authRequestInterceptor(config: InternalAxiosRequestConfig) {
  const token = storage.getItem(storageKey.authToken)
  config.headers = config.headers ?? {}
  if (token) {
    config.headers.Authorization = token
  }
  config.headers.Accept = 'application/json'
  return config
}

const axios = Axios.create({
  baseURL: API_BASE_URL,
})

axios.interceptors.request.use(authRequestInterceptor)
axios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default axios
