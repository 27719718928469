import { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

import notification from '@stores/notification'

import { validAuth } from '@utils/base'

import { AuthLayout } from '@layouts/index'

let hasNavigateBack = false
const AuthProvider: React.FC = () => {
  const navigate = useNavigate()

  useEffect(() => {
    const hasValidAuth = validAuth()
    if (hasValidAuth.isValid && !hasNavigateBack) {
      hasNavigateBack = true
      notification.error('Anda sudah login!')
      navigate('/dashboard')
    }
  }, [])

  return (
    <AuthLayout>
      <Outlet />
    </AuthLayout>
  )
}

export default AuthProvider
