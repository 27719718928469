import { forwardRef } from 'react'

import useAvatarClass from './Avatar.styles'
import { AvatarProps } from './Avatar.types'

export const Avatar = forwardRef<HTMLDivElement, AvatarProps>((props, ref) => {
  const { src, size, className, alt, ...rest } = props

  const classes = useAvatarClass({ size, className })

  return (
    <div className={classes} ref={ref} {...rest}>
      <img
        className='h-full w-full rounded-full object-cover'
        src={src}
        alt={alt}
      />
    </div>
  )
})

Avatar.displayName = 'Avatar'
