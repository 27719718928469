import { useQuery } from '@tanstack/react-query'

import { BaseAll } from '@/types/apis'

import axios from '@libs/axios'
import { ExtractFnReturnType } from '@libs/react-query'

import { UserMeDTO, UserMeOptions } from '../types'

const getUserMe = async ({ params, signal }: BaseAll): Promise<UserMeDTO> => {
  return await axios
    .get('api/v1/user/me', {
      params,
      signal,
    })
    .then((res) => res.data)
}

const useUserMe = ({ config, params }: UserMeOptions = {}) => {
  return useQuery<ExtractFnReturnType<typeof getUserMe>>({
    ...config,
    queryKey: [
      'user-me',
      ...(params ? Object.values(params).filter(Boolean) : []),
    ],
    queryFn: ({ signal }) => getUserMe({ params, signal }),
  })
}

export { getUserMe, useUserMe }
