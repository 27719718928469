import { HiOutlinePlus } from 'react-icons/hi'

import { Breadcrumbs, ButtonLink } from '@elements/index'

import { DashboardTableProps } from './Dashboard.types'

export const DashboardTable: React.FC<DashboardTableProps> = (props) => {
  const { title, breadcrumbTitle, filter, access, children, download } = props

  const hasCreate = access?.hasCreate() || false
  return (
    <div className='wrapper-dashboard'>
      <Breadcrumbs
        items={[
          {
            to: '/dashboard',
            content: 'home',
          },
          {
            to: '',
            content: breadcrumbTitle || title,
          },
        ]}
      />
      <div className='flex w-full justify-between'>
        <h1 className='title'>{title}</h1>
        {download}
      </div>
      <div className='filter-table'>
        {filter}
        {hasCreate ? (
          <ButtonLink
            to='create'
            variant='solid'
            color='primary'
            leftIcon={<HiOutlinePlus className='mr-1 h-5 w-5' />}
          >
            Tambah
          </ButtonLink>
        ) : null}
      </div>
      {children}
    </div>
  )
}
