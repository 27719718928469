import IMask from 'imask'
import { forwardRef, MutableRefObject, useEffect, useState } from 'react'
import { useIMask } from 'react-imask'

import { mergeRefs } from '@utils/refs'

import { Input } from '@forms/index'

import { MaskedInputProps } from './MaskedInput.types'

export const MaskedInput = forwardRef<HTMLInputElement, MaskedInputProps>(
  (props, ref) => {
    const { maskOptions, onChange, ...rest } = props

    const [opts, setOpts] = useState<IMask.AnyMaskedOptions>(maskOptions)
    const { ref: IMaskInput } = useIMask(opts, {
      onAccept(value, maskRef, event?) {
        onChange(value, maskRef, event)
      },
    })

    const inputMaskRef = IMaskInput as MutableRefObject<HTMLInputElement>
    useEffect(() => {
      setOpts(maskOptions)
    }, [maskOptions])

    const refs = mergeRefs([ref, inputMaskRef])
    return <Input ref={refs} {...rest} />
  }
)

MaskedInput.displayName = 'MaskedInput'
