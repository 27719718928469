import { HiOutlinePlus } from 'react-icons/hi'

import { Button, ButtonLink, CustomDashboardAction } from '@elements/index'

import { DashboardActionProps } from './Dashboard.types'
export const DashboardAction: React.FC<DashboardActionProps> = (props) => {
  const { pageType, parentPath, handleDelete, formId, access, cleanAction } =
    props

  if (!pageType.isView) return <></>

  const hasEdit = access?.hasUpdate() || false
  const hasDelete = access?.hasDelete() || false
  return (
    <CustomDashboardAction>
      <ButtonLink to={parentPath} variant='outline' color='primary'>
        Kembali
      </ButtonLink>
      {hasDelete && !cleanAction && handleDelete ? (
        <Button
          type='button'
          variant='outline'
          color='danger'
          onClick={handleDelete}
        >
          Hapus
        </Button>
      ) : null}
      {hasEdit && !cleanAction ? (
        <ButtonLink
          to={parentPath + '/edit/' + formId}
          variant='solid'
          color='primary'
          leftIcon={<HiOutlinePlus className='mr-2 h-5 w-5' />}
        >
          Edit
        </ButtonLink>
      ) : null}
    </CustomDashboardAction>
  )
}
