import { Form, Formik, FormikProps } from 'formik'
import { HiOutlineChevronLeft } from 'react-icons/hi'
import { Link } from 'react-router-dom'

import { validationSchema } from '@utils/validation'

import { FormControl, FormLabel, Input } from '@forms/index'
import { Button, Logo } from '@elements/index'

import { useForgotPassword } from '../hooks'
import { forgotPasswordForm } from '../schema'
import { ForgotPasswordFormDTO } from '../types'

const ForgotPassword = () => {
  const { formValue, handleSubmit } = useForgotPassword()

  return (
    <div className='w-full max-w-md space-y-8 md:space-y-14'>
      <Logo imageClassName='w-auto h-auto max-h-[40px]' />
      <div>
        <h1 className='text-3xl font-bold text-gray-800'>
          Sign in to your account
        </h1>
        <p className='mt-2 text-base leading-tight text-gray-500'>
          No worries, we’ll send you reset instruction
        </p>
      </div>
      <div>
        <Formik
          initialValues={formValue}
          validationSchema={validationSchema(forgotPasswordForm)}
          enableReinitialize
          onSubmit={handleSubmit}
        >
          {({
            values,
            handleChange,
            handleBlur,
            isValid,
            dirty,
            isSubmitting,
          }: FormikProps<ForgotPasswordFormDTO>) => (
            <Form>
              <div className='space-y-8'>
                <FormControl errorKey='email'>
                  <FormLabel>Email Address</FormLabel>
                  <Input
                    variant='outline'
                    color='primary'
                    name='email'
                    placeholder='Type email here...'
                    type='email'
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </FormControl>
                <Button
                  type='submit'
                  variant='solid'
                  color='primary'
                  isFluid
                  disabled={!(isValid && dirty)}
                  isLoading={isSubmitting}
                >
                  Forgot Password
                </Button>
              </div>
              <Link
                to='/login'
                className='mt-3 flex items-center justify-center gap-1 text-sm text-blue-600'
              >
                <HiOutlineChevronLeft className='h-4 w-4' />
                <span>Back to Login</span>
              </Link>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default ForgotPassword
