import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import Mistake from './Mistake'
import { DetailErrorData, DetailErrorProps } from './Mistake.types'

export const DetailError: React.FC<DetailErrorProps> = (props) => {
  const { parentPath, error, isReload, customAction } = props
  const [mistake, setMistake] = useState<DetailErrorData>({
    code: 500,
    title: 'Internal Server Error',
    message: '',
  })

  useEffect(() => {
    if (axios.isAxiosError(error)) {
      setMistake((prev) => ({
        code: error.response?.status || prev.code,
        title: error.response?.statusText || prev.message,
        message: error.response?.data?.message || error?.message || '',
      }))
    }
  }, [])

  const toPath = parentPath || '.'
  return (
    <Mistake code={mistake.code} title={mistake.title}>
      <div className='mt-2'>
        <>
          {mistake.message ? (
            <div className='mb-4 line-clamp-6'>{mistake.message}.</div>
          ) : null}
          {customAction ? (
            customAction
          ) : (
            <Link
              to={toPath}
              reloadDocument={isReload}
              className='text-gray-700 underline'
            >
              Back to home page.
            </Link>
          )}
        </>
      </div>
    </Mistake>
  )
}

export default DetailError
