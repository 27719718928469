import React from 'react'
import { Link } from 'react-router-dom'

import Mistake from './Mistake'
import { PageErrorProps } from './Mistake.types'

export const PageNotFound: React.FC<PageErrorProps> = (props) => {
  const { parentPath } = props

  return (
    <Mistake code='404' title='Page Not Found'>
      <div className='mt-2'>
        The Page you are looking for doesn&apos;t exist or an other error
        occured. Go to{' '}
        <Link to={parentPath || '.'} className='text-gray-700 underline'>
          home page
        </Link>
        .
      </div>
    </Mistake>
  )
}

export default PageNotFound
