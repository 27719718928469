import { useMutation } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'

import { BaseUpdateForm } from '@/types/apis'

import axios from '@libs/axios'

import {
  ResetPasswordDTO,
  ResetPasswordFormDTO,
  ResetPasswordOptions,
} from '../types'

const resetPassword = async ({
  id,
  data,
  signal,
}: BaseUpdateForm<ResetPasswordFormDTO>): Promise<
  AxiosResponse<ResetPasswordDTO>
> => {
  return await axios.post('api/v1/auth/reset-password/' + id, data, {
    signal,
  })
}

const useResetPassword = (props?: ResetPasswordOptions) => {
  return useMutation({
    mutationFn: resetPassword,
    ...props?.config,
  })
}

export { resetPassword, useResetPassword }
