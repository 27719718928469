import { titleCase } from './base'

export const getFileName = (rawFileName: string, delimiter = '_'): string => {
  const arrFileName = rawFileName.split(' ')
  if (arrFileName.length < 2) return rawFileName
  return titleCase(arrFileName[1].replaceAll(delimiter, ' '))
}

export const emptyValueLabel = (value?: any): any => {
  if (value?.value === '' && value?.label === '') {
    return null
  }
  return value
}
