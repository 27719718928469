import { Badge } from '@elements/index'

import { CustomDashboardStatusProps } from './Dashboard.types'

export const CustomDashboardStatus: React.FC<CustomDashboardStatusProps> = (
  props
) => {
  const { title, isLoading, status, children } = props

  return (
    <div className='info-content'>
      <div className='title-wrapper'>
        <h1 className='title'>{title || 'Status'}</h1>
        {status ? (
          isLoading ? (
            <div className='my-4 h-full w-12 animate-pulse bg-gray-300 p-3' />
          ) : (
            <Badge size='lg' variant={status?.variant}>
              {status?.value}
            </Badge>
          )
        ) : null}
      </div>
      <div className='space-y-6'>{children}</div>
    </div>
  )
}
