import React from 'react'

import useInputAddonClass from './InputAddon.styles'
import { InputAddonProps } from './InputAddon.types'

const InputAddon: React.FC<InputAddonProps> = ({
  placement,
  size,
  className,
  disabled,
  ...props
}) => {
  const classes = useInputAddonClass({ size, placement, className, disabled })

  return <div className={classes} {...props} />
}

InputAddon.displayName = 'InputAddon'

export const InputLeftAddon: React.FC<InputAddonProps> = (props) => (
  <InputAddon placement='left' {...props} />
)

InputLeftAddon.displayName = 'InputLeftAddon'

export const InputRightAddon: React.FC<InputAddonProps> = (props) => (
  <InputAddon placement='right' {...props} />
)

InputRightAddon.displayName = 'InputRightAddon'
