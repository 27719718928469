import clsx from 'clsx'
import { HiOutlineChevronLeft } from 'react-icons/hi2'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import { BaseLayoutProps } from '@/types/components'

import { storageKey } from '@constants/index'

import { useAuth } from '@stores/auth'
import notification from '@stores/notification'

import { useDisclosure } from '@hooks/useDisclosure'
import { useNavigation } from '@hooks/useNavigation'

import { storage } from '@utils/storage'

import defaultAvatar from '@images/avatar.svg'

import {
  Avatar,
  Button,
  Dropdown,
  DropdownHandler,
  DropdownMenu,
  Logo,
  Navigation,
} from '@elements/index'

import { navigation } from './data'

const DashboardLayout: React.FC<BaseLayoutProps> = (props) => {
  const location = useLocation()
  const { toggle, isOpen } = useDisclosure()
  const { collapsed, toggleCollapsed } = useNavigation()
  const navigate = useNavigate()
  const [auth] = useAuth()

  const handleLogout = () => {
    storage.removeItem(storageKey.authToken)
    notification.success('Berhasil logout!')
    navigate('/login')
    window.location.reload()
  }

  return (
    <div className='min-h-screen w-full' {...props}>
      <nav className='fixed z-30 w-full border-b border-gray-200 bg-white py-2'>
        <div className='px-3 py-1.5 lg:px-5 lg:pl-3'>
          <div className='flex items-center justify-between gap-3'>
            <div className='flex items-center justify-start gap-3'>
              <Button
                type='button'
                variant='outline'
                className='menu'
                onClick={toggle}
              >
                <div className={clsx('hamburger', isOpen && 'active')}>
                  <div />
                </div>
              </Button>
              <Link to='/dashboard' className='ml-0 lg:ml-4'>
                <Logo imageClassName='w-auto h-auto max-h-[40px]' />
              </Link>
              <Button
                type='button'
                variant='ghost'
                className='!hidden !h-[30px] !min-h-[30px] !w-[30px] !rounded-full !p-0 lg:!block'
                onClick={toggleCollapsed}
              >
                <HiOutlineChevronLeft
                  className={clsx(
                    collapsed ? 'rotate-0' : 'rotate-180',
                    'mx-auto h-5 w-5 transition-all duration-200'
                  )}
                />
              </Button>
            </div>
            <Dropdown placement='bottom-end'>
              <DropdownHandler>
                <div
                  className='flex cursor-pointer items-center justify-center'
                  role='button'
                  tabIndex={0}
                >
                  {auth?.user?.jabatan && (
                    <>
                      <div className='hidden text-gray-600 sm:block'>
                        {auth?.user?.jabatan}
                      </div>
                      <span className='mx-2 hidden h-6 w-0.5 bg-gray-200 sm:block' />
                    </>
                  )}
                  {auth?.user?.nama_lengkap && (
                    <>
                      <div className='hidden text-gray-600 xs:block'>
                        {auth?.user?.nama_lengkap}
                      </div>
                      <span className='mx-2 hidden h-6 w-0.5 bg-gray-200 xs:block' />
                    </>
                  )}
                  <Avatar
                    src={defaultAvatar}
                    alt={`Avatar ${auth?.user?.nama_lengkap}`}
                    size='xl'
                  />
                </div>
              </DropdownHandler>
              <DropdownMenu>
                <div className='dropdown-menu-container w-22 mt-6'>
                  <Link to='/dashboard/profile' className='dropdown-menu'>
                    Profile
                  </Link>
                  <Link to='/dashboard/profile' className='dropdown-menu'>
                    Change Password
                  </Link>
                  <span className='devide' />
                  <button
                    type='button'
                    onClick={handleLogout}
                    className='dropdown-menu'
                  >
                    Logout
                  </button>
                </div>
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
      </nav>
      <div className='relative flex overflow-hidden'>
        {isOpen && (
          <div
            role='button'
            tabIndex={0}
            aria-hidden='true'
            className='absolute inset-0 z-20 min-h-screen w-full cursor-pointer bg-gray-800 bg-opacity-40'
            onClick={toggle}
          />
        )}
        <aside
          className={clsx(
            'mini-scrollbar fixed -left-72 top-0 z-20 flex h-screen flex-col bg-white py-4 pt-20 transition-all duration-200 lg:left-0',
            isOpen && '!left-0',
            collapsed ? 'w-72 overflow-y-auto' : 'w-14'
          )}
        >
          <Navigation
            currentLocation={location.pathname}
            access={auth?.navigation || []}
            currentPath='/dashboard'
            items={navigation}
            collapsed={collapsed}
          />
        </aside>
        <div
          className={clsx(
            'relative ml-0 h-full w-full overflow-y-auto bg-gray-100 transition-all duration-200',
            collapsed ? 'lg:ml-72' : 'lg:ml-16'
          )}
        >
          <main className='min-h-screen pt-16'>{props.children}</main>
        </div>
      </div>
    </div>
  )
}

export default DashboardLayout
