import { useCallback, useEffect, useState } from 'react'
import {
  HiOutlineChevronDown,
  HiOutlineChevronUp,
  HiOutlineInformationCircle,
} from 'react-icons/hi'

import { useDisclosure } from '@hooks/useDisclosure'
import useEventListener from '@hooks/useEventListener'
import { useIsomorphicLayoutEffect } from '@hooks/useIsomorphicLayoutEffect'

import { Button } from '@elements/index'

import { DashboardContentProps } from './Dashboard.types'

export const DashboardContent: React.FC<DashboardContentProps> = (props) => {
  const { title, hasAccordion, children, badge, alertText, alertType } = props

  const accordion = useDisclosure(false)
  const [element, setElement] = useState<HTMLDivElement | null>(null)

  const [height, setHeight] = useState<number>(0)

  const handleSize = useCallback(() => {
    setHeight(element?.offsetHeight || 0)
  }, [element?.offsetHeight, element?.offsetWidth])

  useEventListener('resize', handleSize)

  useIsomorphicLayoutEffect(() => {
    handleSize()
  }, [element?.offsetHeight, element?.offsetWidth])

  useEffect(() => {
    if (!hasAccordion) return
    if (element?.style) {
      element.style.height = accordion.isOpen ? '0px' : `${height}px`
      element.style.overflow = accordion.isOpen ? 'hidden' : 'auto'
    }
  }, [accordion.isOpen])

  return (
    <div className='form-container'>
      <div className='title-wrapper relative'>
        <div className='flex items-center gap-4'>
          <div className='flex items-center justify-center space-x-2'>
            {alertText && (
              <HiOutlineInformationCircle
                className={`h-5 w-5 ${
                  alertType === 'danger' ? 'text-red-600' : 'text-green-700'
                } `}
              />
            )}
            <h1 className='title'>{title}</h1>
          </div>
          <div className='flex items-center justify-center gap-2'>{badge}</div>
        </div>
        {alertText && (
          <div
            className={`absolute -bottom-4 rounded-md border-2 border-gray-200 bg-white px-3 py-1 text-sm   ${
              alertType === 'danger' ? 'text-red-600' : 'text-green-700'
            }`}
          >
            <p>{alertText}</p>
          </div>
        )}
        {hasAccordion ? (
          <Button
            type='button'
            className='icon-only'
            variant='outline'
            onClick={accordion.toggle}
          >
            {accordion.isOpen ? (
              <HiOutlineChevronUp className='h-5 w-5' />
            ) : (
              <HiOutlineChevronDown className='h-5 w-5' />
            )}
          </Button>
        ) : null}
      </div>
      <div className='form-animate' ref={setElement}>
        <div className='form-wrapper'>{children}</div>
      </div>
    </div>
  )
}
