import clsx from 'clsx'
import React, { forwardRef } from 'react'

import { useFormControl } from '@forms/index'

import { FormHelperProps } from './FormHelper.types'

export const FormHelper = forwardRef<HTMLDivElement, FormHelperProps>(
  (props, ref) => {
    const { className, id, ...rest } = props
    const classes = clsx('form-helper', className)
    const formControl = useFormControl({})

    return (
      <div
        ref={ref}
        className={classes}
        id={id || formControl.helpTextId}
        {...rest}
      />
    )
  }
)

FormHelper.displayName = 'FormHelper'
