import { Route, Routes } from 'react-router-dom'

import { AuthProvider } from '@providers/index'

import { PageNotFound } from '@elements/index'

import { ForgotPassword, Login, ResetPassword } from './components'

const routes = () => {
  return (
    <Routes>
      <Route element={<AuthProvider />}>
        <Route path='login' element={<Login />} />
        <Route path='forgot-password' element={<ForgotPassword />} />
        <Route path='reset-password/:token' element={<ResetPassword />} />
      </Route>
      <Route path='*' element={<PageNotFound />} />
    </Routes>
  )
}

export default routes
