import { InputProps } from '@forms/index'

export interface InputElementProps
  extends React.HTMLAttributes<HTMLDivElement>,
    IInputElementProps {}

interface IInputElementProps {
  placement?: InputElementPlacement
  size?: InputProps['size']
  children?: React.ReactNode
  disabledPointerEvents?: boolean
}

export const InputElementPlacement = {
  left: 'left',
  right: 'right',
} as const

export type InputElementPlacement =
  (typeof InputElementPlacement)[keyof typeof InputElementPlacement]

export type InputElementPlacementMap<T> = {
  [size in keyof typeof InputElementPlacement]: T
}
