import { IconType } from 'react-icons'
import {
  FaCheck,
  FaExclamationCircle,
  FaExclamationTriangle,
  FaInfoCircle,
} from 'react-icons/fa'

export interface NotificationFunc {
  error(message: string, lifetime?: number): void
  warning(message: string, lifetime?: number): void
  success(message: string, lifetime?: number): void
  info(message: string, lifetime?: number): void
}

export const NotificationType = {
  error: 'error',
  warning: 'warning',
  success: 'success',
  info: 'info',
} as const

export type NotificationType =
  (typeof NotificationType)[keyof typeof NotificationType]

export type NotificationTypeMap<T> = {
  [type in keyof typeof NotificationType]: T
}

export interface NotificationProps {
  message: string
  type: NotificationType
  lifetime?: number
}

export interface NotificationData extends NotificationProps {
  id: string
  lifetime: number
}

export interface NotificationMessageProps extends NotificationData {
  onRemove(id: string): void
}

export interface NotificationState {
  data: NotificationData[]
  position: NotificationPosition
}

export interface NotificationTypeDataValue {
  base: string
  iconstyle: string
  icon: IconType
  name: string
}

export const notificationTypeData: NotificationTypeMap<NotificationTypeDataValue> =
  {
    info: {
      base: 'bg-white border-blue-500',
      iconstyle: 'text-blue-500',
      icon: FaInfoCircle,
      name: 'Info',
    },
    error: {
      base: 'bg-white border-red-500',
      iconstyle: 'text-red-500',
      icon: FaExclamationCircle,
      name: 'Error',
    },
    warning: {
      base: 'bg-white border-yellow-500',
      iconstyle: 'text-yellow-500',
      icon: FaExclamationTriangle,
      name: 'Warning',
    },
    success: {
      base: 'bg-white border-green-500',
      iconstyle: 'text-green-500',
      icon: FaCheck,
      name: 'Success',
    },
  }

export const NotificationPosition = {
  topLeft: 'topLeft',
  topRight: 'topRight',
  bottomRight: 'bottomRight',
  bottomLeft: 'bottomLeft',
  topMiddle: 'topMiddle',
  bottomMiddle: 'bottomMiddle',
} as const

export type NotificationPosition =
  (typeof NotificationPosition)[keyof typeof NotificationPosition]

export type NotificationPositionMap<T> = {
  [position in keyof typeof NotificationPosition]: T
}

export interface NotificationPositionDataValue {
  className: string
}

export const notificationPositionData: NotificationPositionMap<NotificationPositionDataValue> =
  {
    topLeft: {
      className: 'top-0 left-0',
    },
    topRight: {
      className: 'top-0 right-0',
    },
    bottomRight: {
      className: 'bottom-0 right-0',
    },
    bottomLeft: {
      className: 'bottom-0 left-0',
    },
    topMiddle: {
      className: 'top-0 left-1/2 -translate-x-1/2 transform',
    },
    bottomMiddle: {
      className: 'bottom-0 left-1/2 -translate-x-1/2 transform',
    },
  }
