import React from 'react'

export type BreadcrumbsProps = React.HTMLProps<HTMLElement> & IBreadcrumbsProps

export interface BreadcrumbsData {
  to: string
  content: string
}

export interface IBreadcrumbsProps {
  items: BreadcrumbsData[]
  separator?: JSX.Element
}

export const BreadcrumbsSize = {
  md: 'md',
  lg: 'lg',
} as const

export type BreadcrumbsSize =
  (typeof BreadcrumbsSize)[keyof typeof BreadcrumbsSize]

export type BreadcrumbsSizeMap<T> = {
  [size in keyof typeof BreadcrumbsSize]: T
}
