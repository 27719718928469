/* 
Original code:
https://github.com/ciriousjoker/ts-localstorage/blob/main/src/index.ts
*/

class LocalStorageAssertionError extends Error {
  constructor(message?: string) {
    super(message)
    this.name = 'LocalStorageAssertionError'
  }
}

interface LocalKeyParams<T = unknown> {
  hasDefaultValue?: boolean
  toStorage?: (value: T) => string
  fromStorage?: (value: string) => T
}

export class LocalKey<T = unknown> {
  public readonly hasDefaultValue: boolean = false
  public readonly hasCustomConverter: boolean = false

  constructor(
    public readonly key: string,
    public readonly sampleValue: T | null,
    params?: LocalKeyParams<T>
  ) {
    assert(
      typeof sampleValue !== 'function',
      'Serializing functions to localStorage is forbidden since you could only get them back using eval().'
    )

    this.hasDefaultValue = params?.hasDefaultValue ?? this.hasDefaultValue

    const paramsWithConverter = params as LocalKeyParams<T> | undefined

    const hasToStorage = paramsWithConverter?.toStorage !== undefined
    const hasFromStorage = paramsWithConverter?.toStorage !== undefined
    assert(
      hasToStorage === hasFromStorage,
      'Either both or none of toStorage and fromStorage must be defined.'
    )
    if (paramsWithConverter?.toStorage !== undefined) {
      this.toStorage = paramsWithConverter.toStorage
    }
    if (paramsWithConverter?.fromStorage !== undefined) {
      this.fromStorage = paramsWithConverter.fromStorage
    }
  }

  public readonly toStorage = (value: T): string => {
    const type = typeof this.sampleValue

    if (type === 'boolean') return (value as unknown as boolean).toString()
    if (type === 'number') return (value as unknown as number).toString()
    if (type === 'string') return value as unknown as string

    if (this.sampleValue instanceof String) {
      return (value as unknown as string).toString() as string
    }

    return JSON.stringify(value)
  }

  public readonly fromStorage = (value: string): T => {
    const type = typeof this.sampleValue

    if (type === 'boolean')
      return JSON.parse(value.toLowerCase()) as unknown as T
    if (type === 'number') return parseFloat(value) as unknown as T
    if (type === 'string') return value as unknown as T

    if (this.sampleValue instanceof Boolean)
      return new Boolean(value) as unknown as T
    if (this.sampleValue instanceof Number)
      return new Number(value) as unknown as T
    if (this.sampleValue instanceof String)
      return new String(value) as unknown as T

    return JSON.parse(value)
  }
}

function setItem<T>(key: LocalKey<T>, value: T | null | undefined) {
  if (value === null || value === undefined) {
    storage.removeItem(key)
    return
  }
  const stringified = key.toStorage(value)
  return localStorage.setItem(key.key, stringified)
}

function getItem<T>(key: LocalKey<T>): T | null {
  const result = localStorage.getItem(key.key)
  if (result === null || result === undefined) {
    if (key.hasDefaultValue) return key.sampleValue
    return null
  }

  return key.fromStorage(result) as T
}

function removeItem(key: LocalKey<any>): void {
  return localStorage.removeItem(key.key)
}

function clear(): void {
  return localStorage.clear()
}

function key<T = unknown>(index: number, defaultValue: T): LocalKey<T> | null {
  const key = localStorage.key(index)
  if (key === null) return null
  return new LocalKey<T>(key, defaultValue)
}

function length(): number {
  return localStorage.length
}

function assert(check: boolean, message?: string): asserts check {
  if (!check) throw new LocalStorageAssertionError(message)
}

export const storage = { setItem, getItem, removeItem, clear, key, length }
