import { FormikErrors } from 'formik'
import { z } from 'zod'

import { GetFormikError } from '@/types/utils'

/* 
Original code:
https://github.com/robertLichtnow/zod-formik-adapter/blob/master/index.ts
*/
export class ValidationError extends Error {
  public name = 'ValidationError'
  public inner: Array<{ path: string; message: string }> = []
  public constructor(message: string) {
    super(message)
  }
}

function createValidationError(e: z.ZodError) {
  const error = new ValidationError(e.message)
  error.inner = e.errors.map((err) => ({
    message: err.message,
    path: err.path.join('.'),
  }))

  return error
}

export function validationSchema<T>(
  schema: z.ZodSchema<T>,
  params?: Partial<z.ParseParams>
): { validate: (obj: T) => Promise<void> } {
  return {
    async validate(obj: T) {
      try {
        await schema.parseAsync(obj, params)
      } catch (err: unknown) {
        throw createValidationError(err as z.ZodError<T>)
      }
    },
  }
}

export const getFormikError = (
  errors: FormikErrors<any>,
  key: string
): GetFormikError => {
  if (!errors[key]) return undefined
  return errors[key] as string
}
