import { FormikHelpers } from 'formik'

import confirmation, { removeConfirmation } from '@stores/confirmation'

import { getErrorMessage, getMessage } from '@utils/apis'

import { useForgotPassword as useForgotPasswordService } from '../services'
import { ForgotPasswordFormDTO } from '../types'

const useForgotPassword = () => {
  const formValue: ForgotPasswordFormDTO = {
    email: '',
  }

  const forgotPassword = useForgotPasswordService()

  const handleForgotPassword = async (
    values: ForgotPasswordFormDTO,
    formikHelpers: FormikHelpers<ForgotPasswordFormDTO>,
    isFirst = false
  ) => {
    formikHelpers.setSubmitting(true)
    await forgotPassword
      .mutateAsync({
        data: values,
      })
      .then((res) => {
        confirmation.alert({
          title: getMessage(res),
          message: (
            <div className='-mb-3 mt-4 flex flex-col text-base'>
              <span>We sent password reset link to</span>
              <b>{values.email}</b>
            </div>
          ),
          footer: isFirst ? (
            <div className='mt-6 flex flex-col text-base leading-snug text-gray-500'>
              <span>Don’t receive the email?</span>
              <button
                type='button'
                onClick={() => {
                  if (isFirst) {
                    return handleForgotPassword(values, formikHelpers)
                  }
                  removeConfirmation()
                }}
                disabled={!isFirst}
                className='text-blue-800'
              >
                Click to resend
              </button>
            </div>
          ) : undefined,
          dismiss: {
            closeButton: true,
            escapeKey: true,
            outsidePress: true,
          },
        })
      })
      .catch((err) => {
        confirmation.alert({
          icon: 'error',
          title: 'Oops!',
          message: getErrorMessage(err),
        })
      })
      .finally(() => {
        formikHelpers.setSubmitting(false)
      })
  }

  const handleSubmit = async (
    values: ForgotPasswordFormDTO,
    formikHelpers: FormikHelpers<ForgotPasswordFormDTO>
  ) => handleForgotPassword(values, formikHelpers, true)

  return {
    formValue,
    handleSubmit,
  }
}

export default useForgotPassword
