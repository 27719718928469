import { Breadcrumbs } from '@elements/index'

import { DashboardDetailProps } from './Dashboard.types'
import { DashboardAction } from './DashboardAction'
import { DashboardStatus } from './DashboardStatus'

export const DashboardDetail: React.FC<DashboardDetailProps> = (props) => {
  const {
    action,
    breadcrumbs,
    children,
    formId,
    handleDelete,
    isLoading,
    pageType,
    parentPath,
    status,
    title,
    access,
    customAction,
    customStatus,
    cleanAction,
  } = props

  const getParentPath = parentPath || breadcrumbs[1].to

  return (
    <div className='wrapper-dashboard'>
      <Breadcrumbs items={breadcrumbs} />
      <div className='header-wrapper'>
        <h1 className='title'>{title}</h1>
        {customAction ? (
          customAction({
            access,
            formId: formId || '',
            pageType,
            parentPath: getParentPath,
            handleDelete,
          })
        ) : (
          <DashboardAction
            access={access}
            cleanAction={cleanAction}
            pageType={pageType}
            parentPath={getParentPath}
            handleDelete={handleDelete}
            formId={formId || ''}
          />
        )}
      </div>
      <div className='content-wrapper'>
        <div className='wrapper-form'>{children}</div>
        {customStatus ? (
          customStatus({
            isLoading: isLoading || false,
            pageType,
            action,
            status,
          })
        ) : (
          <DashboardStatus
            pageType={pageType}
            isLoading={isLoading || false}
            status={status}
            action={action}
          />
        )}
      </div>
    </div>
  )
}
