import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import React, { Suspense } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { BrowserRouter } from 'react-router-dom'

import { NotificationContainer } from '@/components/notifications'

import { GlobalAuthProvider } from '@providers/index'

import { queryClient } from '@libs/react-query'

import AppRoutes from '@routes/index'

import { Confirmation } from '@components/confirmation'
import { ButtonRefresh, LoadingScreen, Mistake } from '@elements/index'

function App(): JSX.Element {
  return (
    <Suspense fallback={<LoadingScreen reason='Initial app' />}>
      <ErrorBoundary
        FallbackComponent={(): JSX.Element => {
          return (
            <Mistake code='Opps' title='Something went wrong :('>
              <div className='mt-6'>
                <ButtonRefresh />
              </div>
            </Mistake>
          )
        }}
      >
        <QueryClientProvider client={queryClient}>
          {process.env.NODE_ENV !== 'production' && <ReactQueryDevtools />}
          <NotificationContainer />
          <Confirmation />
          <BrowserRouter>
            <GlobalAuthProvider>
              <AppRoutes />
            </GlobalAuthProvider>
          </BrowserRouter>
        </QueryClientProvider>
      </ErrorBoundary>
    </Suspense>
  )
}

export default App
