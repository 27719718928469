import React, { isValidElement } from 'react'

import { ValidToken } from '@/types/utils'

import { storageKey } from '@constants/index'

import { ParamsToStore } from '@elements/table/Table.types'

import { storage } from './storage'

export function getProperty<T, K extends keyof T>(obj: T, key: K): T[K] {
  return obj[key]
}

export function getValidChildren(children: React.ReactNode) {
  return React.Children.toArray(children).filter((child) =>
    isValidElement(child)
  ) as React.ReactElement[]
}

export const validAuth = (): ValidToken => {
  const userToken = storage.getItem(storageKey.authToken)
  if (!userToken) {
    return {
      isValid: false,
      message: 'Harap login terlebih dahulu',
    }
  }
  try {
    const token = JSON.parse(atob(userToken.split('.')[1]))
    if (token.exp * 1000 < Date.now()) {
      return {
        isValid: false,
        message: 'Waktu login sudah habis, silahkan login kembali',
      }
    }
    return {
      isValid: true,
      message: 'User valid',
    }
  } catch (e) {
    return {
      isValid: false,
      message: 'Harap login terlebih dahulu',
    }
  }
}

export const uuidv4 = (): string => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0
    const v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

export const stringToFloat = (str: string): number => {
  return parseFloat(str) || 0
}

export const stringToInt = (str: string): number => {
  return parseInt(str) || 0
}

export const isValidDate = (d: Date) => {
  return d instanceof Date && !isNaN(d.getTime())
}

export const titleCase = (str: string): string => {
  return str
    .toLowerCase()
    .split(' ')
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1)
    })
    .join(' ')
}

export const isEmptyObject = (value: any): boolean => {
  return Object.keys(value).length === 0 && value.constructor === Object
}

export const arrClean = <T>(arr: T[]): T[] => {
  return arr.filter((val: any) => val != '' && val != null)
}

export const emptyString = (str: string): boolean => {
  return str === ''
}

export const formatIDR = (num: number): string => {
  return num.toLocaleString('id-ID', {
    style: 'currency',
    currency: 'IDR',
  })
}

export const thousandSeparator = (num: number): string => {
  return num.toLocaleString('id-ID', { useGrouping: true })
}

export const terbilang = (nilai: number | undefined): string => {
  const huruf = [
    '',
    'Satu',
    'Dua',
    'Tiga',
    'Empat',
    'Lima',
    'Enam',
    'Tujuh',
    'Delapan',
    'Sembilan',
    'Sepuluh',
    'Sebelas',
  ]
  let temp = ''
  if (nilai !== undefined) {
    if (nilai < 12) {
      temp = ' ' + huruf[nilai]
    } else if (nilai < 20) {
      temp = terbilang(nilai - 10) + ' Belas'
    } else if (nilai < 100) {
      temp =
        terbilang(Math.floor(nilai / 10)) + ' Puluh' + terbilang(nilai % 10)
    } else if (nilai < 200) {
      temp = ' Seratus' + terbilang(nilai - 100)
    } else if (nilai < 1000) {
      temp =
        terbilang(Math.floor(nilai / 100)) + ' Ratus' + terbilang(nilai % 100)
    } else if (nilai < 2000) {
      temp = ' Seribu' + terbilang(nilai - 1000)
    } else if (nilai < 1000000) {
      temp =
        terbilang(Math.floor(nilai / 1000)) + ' Ribu' + terbilang(nilai % 1000)
    } else if (nilai < 1000000000) {
      temp =
        terbilang(Math.floor(nilai / 1000000)) +
        ' Juta' +
        terbilang(nilai % 1000000)
    } else if (nilai < 1000000000000) {
      temp =
        terbilang(Math.floor(nilai / 1000000000)) +
        ' Milyar' +
        terbilang(nilai % 1000000000)
    } else if (nilai < 1000000000000000) {
      temp =
        terbilang(Math.floor(nilai / 1000000000000)) +
        ' Trilyun' +
        terbilang(nilai % 1000000000000)
    }
    return temp
  } else return 'NaN'
}

export const setTableLastParams = (el: ParamsToStore) => {
  const dataToStore = JSON.stringify(el)
  localStorage.setItem('tableParams', dataToStore)
}

export const getTableLastCurrentParams = (location: string) => {
  const storedData = {
    currentPage: 1,
    pathname: '',
    query: undefined,
    sorting: [],
  }
  const tempItem = localStorage.getItem('tableParams')
  if (tempItem) {
    const item = tempItem ? JSON.parse(tempItem) : null
    if (item.pathname === location) {
      storedData.currentPage = item.currentPage
      storedData.pathname = item.pathname
      storedData.query = item.query
      storedData.sorting = item.sorting
      return storedData
    }
  }
  return storedData
}

export const removeStoredLastCurrentPage = () => {
  localStorage.removeItem('tableParams')
}
