import clsx from 'clsx'
import React, { forwardRef, useCallback, useEffect, useState } from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'
import { HiOutlineDocument, HiOutlinePlusCircle } from 'react-icons/hi'

import notification from '@stores/notification'

import { arrClean, uuidv4 } from '@utils/base'
import { getFileName } from '@utils/components'

import { FileContent } from './FileContent'
import { FileItem, FileUploadData, FileUploadProps } from './FileUpload.types'
import { useFormControl } from '..'

export const FileUpload = forwardRef<HTMLDivElement, FileUploadProps>(
  (props, ref) => {
    const {
      onChange,
      initialValue,
      isMulti,
      acceptFile = { 'application/pdf': ['.pdf'] },
      maxSize = 100000,
      showPreview = true,
      ...rest
    } = props

    const [files, setFiles] = useState<FileItem[]>([])
    const { disabled } = useFormControl(props)

    const handleInitialValue = (value?: FileUploadData): string[] => {
      if (!value) return []
      if (Array.isArray(value)) return arrClean<string>(value)
      return arrClean<string>([value])
    }

    useEffect(() => {
      if (!initialValue) return
      const arrFile = handleInitialValue(initialValue)
      const newFiles = arrFile.map((val): FileItem => {
        return {
          id: uuidv4(),
          fileName: getFileName(val),
          fileUrl: val,
        }
      })
      setFiles(newFiles)
    }, [initialValue])

    useEffect(() => {
      const mapFiles = files.reduce((acc, val) => {
        if (val?.fileUrl) {
          acc = [...acc, val?.fileUrl]
        }
        return acc
      }, [] as string[])
      onChange(isMulti ? mapFiles : mapFiles?.[0] || '')
    }, [files])

    const onDrop = useCallback(
      (acceptFiles: File[], rejectFiles: FileRejection[]) => {
        rejectFiles.map((val) => {
          const fileName = val.file.name
          val.errors.map((err) => {
            const errorMessage =
              err.code === 'file-too-large'
                ? 'Ukuran maximum file yang diunggah adalah 10Mb'
                : 'Type file yang diunggah harus berbentuk pdf'
            notification.error(`${fileName} ${errorMessage}`)
          })
        })

        const newFiles = acceptFiles.map(
          (val): FileItem => ({
            id: uuidv4(),
            fileName: val.name,
            file: val,
          })
        )

        setFiles((prev) => [...prev, ...newFiles])
      },
      []
    )

    const { getRootProps, getInputProps } = useDropzone({
      onDrop,
      noKeyboard: true,
      accept: acceptFile,
      maxSize,
    })

    return (
      <div className='flex w-full flex-wrap gap-3' ref={ref} {...rest}>
        {files.map((file, idx) => (
          <FileContent
            key={idx}
            item={file}
            disabled={disabled}
            setFiles={setFiles}
            showPreview={showPreview}
          />
        ))}
        {((!disabled && isMulti) ||
          (!disabled && !isMulti && files.length < 1)) && (
          <div
            {...getRootProps({
              className: clsx('file-upload', files.length > 0 && 'active'),
            })}
          >
            <input {...getInputProps()} />
            <div className='flex flex-col items-center justify-center pb-6 pt-5'>
              <HiOutlinePlusCircle className='mb-3 h-10 w-10' />
              <p className='mb-2 text-sm'>
                <b>Upload a file </b>
                <span className='text-gray-500'>or drag and drop</span>
              </p>
              <p className='text-center text-gray-500'>Max file size 10 Mb</p>
              <p className='text-center text-gray-500'>Type file adalah pdf</p>
              <p className='text-center text-gray-500'>
                Nama file yang diunggap tidak boleh mengandung karakter berikut:
              </p>
              <p className='text-center'>
                \ / : ? * % &#8223; &#8249; &#8250; |{' '}
              </p>
            </div>
          </div>
        )}
        {disabled && files.length === 0 && (
          <div className='file-upload'>
            <div className='flex flex-col items-center justify-center pb-6 pt-5'>
              <HiOutlineDocument className='mb-3 h-10 w-10' />
              <p className='mb-2 text-sm'>
                <b>No file available</b>
              </p>
            </div>
          </div>
        )}
      </div>
    )
  }
)

FileUpload.displayName = 'FileUpload'
