import clsx from 'clsx'
import { forwardRef } from 'react'

import logoImage from '@images/logo/bag-logo.png'

import { LogoProps } from './Logo.types'

export const Logo = forwardRef<HTMLDivElement, LogoProps>((props, ref) => {
  const { className, imageClassName, ...rest } = props
  return (
    <div ref={ref} className={clsx(className)} {...rest}>
      <img src={logoImage} className={imageClassName} alt='Logo' />
    </div>
  )
})

Logo.displayName = 'Logo'
