import { Form, Formik, FormikProps } from 'formik'
import { useState } from 'react'
import {
  HiOutlineChevronLeft,
  HiOutlineEye,
  HiOutlineEyeOff,
} from 'react-icons/hi'
import { Link } from 'react-router-dom'

import { validationSchema } from '@utils/validation'

import {
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
} from '@forms/index'
import { Button, Logo } from '@elements/index'

import { useResetPassword } from '../hooks'
import { resetPasswordForm } from '../schema'
import { ResetPasswordFormDTO } from '../types'

const ResetPassword = () => {
  const { formValue, handleSubmit } = useResetPassword()
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const toggleShowPassword = () => {
    setShowPassword((prev) => !prev)
  }

  return (
    <div className='w-full max-w-md space-y-8 md:space-y-14'>
      <Logo imageClassName='w-auto h-auto max-h-[40px]' />
      <div>
        <h1 className='text-3xl font-bold text-gray-800'>Reset Password</h1>
        <p className='mt-2 text-base leading-tight text-gray-500'>
          Here you can make your new password
        </p>
      </div>
      <div>
        <Formik
          initialValues={formValue}
          validationSchema={validationSchema(resetPasswordForm)}
          enableReinitialize
          onSubmit={handleSubmit}
        >
          {({
            values,
            handleChange,
            handleBlur,
            isValid,
            dirty,
            isSubmitting,
          }: FormikProps<ResetPasswordFormDTO>) => (
            <Form>
              <div className='space-y-8'>
                <FormControl errorKey='password'>
                  <FormLabel>Password</FormLabel>
                  <InputGroup>
                    <Input
                      color='primary'
                      variant='outline'
                      placeholder='Password'
                      name='password'
                      type={showPassword ? 'text' : 'password'}
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <InputRightElement
                      onClick={toggleShowPassword}
                      className='cursor-pointer'
                    >
                      {showPassword ? (
                        <HiOutlineEyeOff className='h-5 w-5 text-gray-500' />
                      ) : (
                        <HiOutlineEye className='h-5 w-5 text-gray-500' />
                      )}
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
                <FormControl errorKey='password_confirmation'>
                  <FormLabel>Ulangi Password</FormLabel>
                  <InputGroup>
                    <Input
                      color='primary'
                      variant='outline'
                      placeholder='Ulangi Password'
                      name='password_confirmation'
                      type={showPassword ? 'text' : 'password'}
                      value={values.password_confirmation}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <InputRightElement
                      onClick={toggleShowPassword}
                      className='cursor-pointer'
                    >
                      {showPassword ? (
                        <HiOutlineEyeOff className='h-5 w-5 text-gray-500' />
                      ) : (
                        <HiOutlineEye className='h-5 w-5 text-gray-500' />
                      )}
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
                <Button
                  type='submit'
                  variant='solid'
                  color='primary'
                  isFluid
                  disabled={!(isValid && dirty)}
                  isLoading={isSubmitting}
                >
                  Reset Password
                </Button>
              </div>
              <Link
                to='/login'
                className='mt-3 flex items-center justify-center gap-1 text-sm text-blue-600'
              >
                <HiOutlineChevronLeft className='h-4 w-4' />
                <span>Back to Login</span>
              </Link>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default ResetPassword
