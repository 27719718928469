import Dropzone, { DropzoneProps } from 'react-dropzone'
import { HiOutlineCloudArrowUp } from 'react-icons/hi2'

export function Upload(props: DropzoneProps) {
  return (
    <Dropzone {...props}>
      {({ getRootProps, getInputProps, fileRejections, acceptedFiles }) => {
        const acceptedFileItems = acceptedFiles.map((file, id) => (
          <li key={id}>{file.name}</li>
        ))

        return (
          <div className='flex w-full flex-col'>
            <div
              {...getRootProps()}
              className='flex h-24 w-full cursor-pointer flex-col items-center justify-center rounded-xl border-2 border-dashed border-gray-200 shadow-md '
            >
              <input {...getInputProps()} />
              <HiOutlineCloudArrowUp className='h-5 w-5 text-[#2159A2]' />
              <p>Upload file</p>
            </div>
            <div>{acceptedFileItems}</div>
            {fileRejections.length > 0 && (
              <span className='pt-4'>
                Maksimal file yang bisa di upload berjumlah {props.maxFiles}{' '}
                files
              </span>
            )}
          </div>
        )
      }}
    </Dropzone>
  )
}

export default Upload
