import { Form, Formik, FormikProps } from 'formik'
import { useState } from 'react'
import { HiOutlineEye, HiOutlineEyeOff } from 'react-icons/hi'
import { Link } from 'react-router-dom'

import { validationSchema } from '@utils/validation'

import {
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
} from '@forms/index'
import { Button, Logo } from '@elements/index'

import { useLogin } from '../hooks'
import { loginForm } from '../schema'
import { LoginFormDTO } from '../types'

const Login = () => {
  const { formValue, handleSubmit } = useLogin()
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const toggleShowPassword = () => {
    setShowPassword((prev) => !prev)
  }

  return (
    <div className='w-full max-w-md space-y-8 md:space-y-14'>
      <Logo imageClassName='w-auto h-auto max-h-[40px]' />
      <h1 className='text-3xl font-bold text-gray-800'>
        Sign in to your account
      </h1>
      <div>
        <Formik
          initialValues={formValue}
          validationSchema={validationSchema(loginForm)}
          enableReinitialize
          onSubmit={handleSubmit}
        >
          {({
            values,
            handleChange,
            handleBlur,
            isValid,
            dirty,
            isSubmitting,
          }: FormikProps<LoginFormDTO>) => (
            <Form>
              <div className='space-y-6'>
                <FormControl errorKey='email'>
                  <FormLabel>Email Address</FormLabel>
                  <Input
                    variant='outline'
                    color='primary'
                    name='email'
                    placeholder='Type email here...'
                    type='email'
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </FormControl>
                <FormControl errorKey='password'>
                  <FormLabel>Password</FormLabel>
                  <InputGroup>
                    <Input
                      color='primary'
                      variant='outline'
                      name='password'
                      type={showPassword ? 'text' : 'password'}
                      placeholder='Type password here...'
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <InputRightElement
                      onClick={toggleShowPassword}
                      className='cursor-pointer'
                    >
                      {showPassword ? (
                        <HiOutlineEyeOff className='h-5 w-5 text-gray-500' />
                      ) : (
                        <HiOutlineEye className='h-5 w-5 text-gray-500' />
                      )}
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
              </div>
              <div className='mb-6 mt-3 flex flex-row justify-end'>
                <Link to='/forgot-password' className='text-sm text-blue-600'>
                  Forgot your password ?
                </Link>
              </div>
              <Button
                type='submit'
                variant='solid'
                color='primary'
                isFluid
                disabled={!(isValid && dirty)}
                isLoading={isSubmitting}
              >
                Sign in
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default Login
