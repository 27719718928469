import { DashboardCardProps } from './Dashboard.types'

const DashboardCard = (props: DashboardCardProps) => {
  const { name, value, className } = props

  return (
    <div
      key={name}
      className={`${className} flex h-[100px] w-full flex-col items-start justify-center rounded-md p-4 text-white`}
    >
      <div className='text-xl font-bold'>{value}</div>
      <div>{name}</div>
    </div>
  )
}

export default DashboardCard
