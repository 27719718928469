import clsx from 'clsx'
import React, { useEffect } from 'react'

import {
  NotificationMessageProps,
  notificationTypeData,
} from './Notification.types'

export function NotificationMessage({
  id,
  message,
  lifetime,
  onRemove,
  type,
}: NotificationMessageProps): JSX.Element {
  const notificationType = notificationTypeData[type]

  useEffect(() => {
    if (lifetime && onRemove) {
      setTimeout(() => {
        onRemove(id)
      }, lifetime)
    }
  }, [lifetime])

  return (
    <div
      className={clsx(
        'visible flex max-h-40 w-full flex-row shadow-lg',
        'cursor-pointer rounded-md border-l-4 duration-100',
        'hover:scale-102 transform transition-all',
        notificationType.base
      )}
      onClick={() => onRemove && onRemove(id)}
      aria-hidden='true'
    >
      <div className='flex-no-wrap flex w-full flex-row gap-2 p-4'>
        {notificationType.icon && (
          <notificationType.icon
            className={clsx(
              'mx-auto mr-1 flex h-8 w-8 select-none items-center text-xl',
              'mx-auto' && notificationType.iconstyle
            )}
          />
        )}

        <div className='flex-no-wrap flex w-full flex-col px-1'>
          <div className='my-auto flex select-none font-bold text-gray-800'>
            {notificationType.name}
          </div>
          <p className='my-auto line-clamp-2 flex break-all text-sm leading-tight text-gray-500'>
            {message}
          </p>
        </div>
      </div>
    </div>
  )
}
