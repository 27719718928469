import { InputProps } from '@forms/index'

export interface IInputAddonProps {
  placement?: InputAddonPlacement
  size?: InputProps['size']
  disabled?: boolean
}

export interface InputAddonProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'size'>,
    IInputAddonProps {}

export const InputAddonPlacement = {
  left: 'left',
  right: 'right',
} as const

export type InputAddonPlacement =
  (typeof InputAddonPlacement)[keyof typeof InputAddonPlacement]

export type InputAddonPlacementMap<T> = {
  [size in keyof typeof InputAddonPlacement]: T
}
