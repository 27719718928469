import clsx from 'clsx'
import React, { forwardRef } from 'react'
import { Link } from 'react-router-dom'

import { removeStoredLastCurrentPage } from '@utils/base'

import { NavigationItemMenuProps } from './Navigation.types'
import { NavigationSubMenu } from './NavigationSubMenu'

export const NavigationMenu = forwardRef<
  HTMLLIElement,
  NavigationItemMenuProps
>((props, ref): JSX.Element => {
  const { contentData, collapsed, menuIndex, ...rest } = props

  if (contentData?.children) {
    return (
      <li ref={ref} {...rest}>
        <NavigationSubMenu
          menuIndex={menuIndex}
          contentData={contentData}
          collapsed={collapsed}
        />
      </li>
    )
  }

  return (
    <>
      {contentData?.access && (
        <li ref={ref} {...rest}>
          <div className='group relative'>
            <Link
              to={contentData.to}
              onClick={() => removeStoredLastCurrentPage()}
              className={clsx('menu', contentData?.isActive && 'active')}
            >
              <div className='flex'>
                {contentData?.icon && (
                  <contentData.icon className='mr-2 h-6 w-6' />
                )}
                <span
                  className={clsx(
                    collapsed
                      ? 'opacity-100'
                      : 'w-0 overflow-hidden opacity-0 group-hover:w-auto',
                    'whitespace-nowrap transition-all duration-200'
                  )}
                >
                  {contentData.title}
                </span>
              </div>
            </Link>
            {!collapsed && (
              <div className='dropdown-menu absolute left-[120%] top-0 z-50 hidden h-auto group-hover:block'>
                <ul className='top-0 w-48 bg-white shadow-md'>
                  <li>
                    <Link
                      to={contentData.to}
                      onClick={() => removeStoredLastCurrentPage()}
                      className={clsx(
                        'menu',
                        contentData?.isActive && 'active',
                        'bg-blue-50 !pl-4'
                      )}
                    >
                      <div className='flex'>
                        <span className={clsx('whitespace-nowrap')}>
                          {contentData.title}
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </li>
      )}
    </>
  )
})

NavigationMenu.displayName = 'NavigationMenu'
