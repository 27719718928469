import { RouteObject } from 'react-router-dom'

import { DashboardProvider } from '@providers/index'

import { lazyImport } from '@utils/performance'

import { PageNotFound } from '@elements/index'

const { DashboardRoutes } = lazyImport(
  () => import('@features/dashboard/home'),
  'DashboardRoutes'
)

const { DashboardPenjualanRoutes } = lazyImport(
  () => import('@/features/dashboard/dashboard-penjualan'),
  'DashboardPenjualanRoutes'
)

const { DashboardNiagaRoutes } = lazyImport(
  () => import('@/features/dashboard/dashboard-niaga'),
  'DashboardNiagaRoutes'
)

const { DashboardBBORoutes } = lazyImport(
  () => import('@/features/dashboard/dashboard-bbo'),
  'DashboardBBORoutes'
)

const { UsersRoutes } = lazyImport(
  () => import('@features/users'),
  'UsersRoutes'
)

const { ProfileRoutes } = lazyImport(
  () => import('@features/profile'),
  'ProfileRoutes'
)

const { ModaRoutes } = lazyImport(
  () => import('@features/master-data/moda'),
  'ModaRoutes'
)

const { PoLRoutes } = lazyImport(
  () => import('@features/master-data/pol'),
  'PoLRoutes'
)

const { KapalRoutes } = lazyImport(
  () => import('@features/master-data/kapal'),
  'KapalRoutes'
)

const { PoDRoutes } = lazyImport(
  () => import('@features/master-data/pod'),
  'PoDRoutes'
)

const { PemberiKerjaRoutes } = lazyImport(
  () => import('@features/master-data/pemberi-kerja'),
  'PemberiKerjaRoutes'
)

const { RouteRoutes } = lazyImport(
  () => import('@features/master-data/route'),
  'RouteRoutes'
)

const { TerminRoutes } = lazyImport(
  () => import('@features/master-data/termin'),
  'TerminRoutes'
)

const { PemasokRoutes } = lazyImport(
  () => import('@features/master-data/pemasok'),
  'PemasokRoutes'
)

const { MitraKSORoutes } = lazyImport(
  () => import('@features/master-data/mitra-kso'),
  'MitraKSORoutes'
)

const { KontrakPemberiKerjaRoutes } = lazyImport(
  () => import('@features/kontrak/pemberi-kerja'),
  'KontrakPemberiKerjaRoutes'
)

const { AgendaRakorRoutes } = lazyImport(
  () => import('@features/shipment-order/agenda-rakor'),
  'AgendaRakorRoutes'
)
const { ShipmentRoutes } = lazyImport(
  () => import('@features/shipment-order/shipment'),
  'ShipmentRoutes'
)

const { SailingInstructionRoutes } = lazyImport(
  () => import('@features/shipment-order/sailing-instruction'),
  'SailingInstructionRoutes'
)

const { KontrakMitraKSORoutes } = lazyImport(
  () => import('@features/kontrak/mitra-kso'),
  'KontrakMitraKSORoutes'
)

const { ShipmentOperationRoutes } = lazyImport(
  () => import('@features/operation/shipment'),
  'ShipmentOperationRoutes'
)

const { InsuranceClaimRoutes } = lazyImport(
  () => import('@features/insurance/claim'),
  'InsuranceClaimRoutes'
)
const { MonitoringSlaRoutes } = lazyImport(
  () => import('@features/monitoring/monitoring-sla'),
  'MonitoringSlaRoutes'
)

const { ShipmentInsuranceRoutes } = lazyImport(
  () => import('@features/insurance/polis'),
  'ShipmentInsuranceRoutes'
)

const { ArchiveOperationRoutes } = lazyImport(
  () => import('@features/operation/arsip'),
  'ArchiveOperationRoutes'
)

const { SPALRoutes } = lazyImport(
  () => import('@features/shipment-order/spal'),
  'SPALRoutes'
)

const { BbmRoutes } = lazyImport(() => import('@features/bbm'), 'BbmRoutes')

// const { PenagihanPemberiKerjaRoutes } = lazyImport(
//   () => import('@features/penagihan/pemberi-kerja'),
//   'PenagihanPemberiKerjaRoutes'
// )

const { PenagihanMitraRoutes } = lazyImport(
  () => import('@features/penagihan/mitra'),
  'PenagihanMitraRoutes'
)

const { PembayaranMitraRoutes } = lazyImport(
  () => import('@features/pembayaran-mitra'),
  'PembayaranMitraRoutes'
)

const { PendapatanBatuBaraRoutes } = lazyImport(
  () => import('@features/laporan/pendapatan-batubara'),
  'PendapatanBatuBaraRoutes'
)

const { RasioOperasionalRoutes } = lazyImport(
  () => import('@features/laporan/rasio-operasional'),
  'RasioOperasionalRoutes'
)

const { ShipmentTepatWaktuRoutes } = lazyImport(
  () => import('@features/laporan/shipment-tepat-waktu'),
  'ShipmentTepatWaktuRoutes'
)

const { LaporanCashInRoutes } = lazyImport(
  () => import('@features/laporan/cash-in'),
  'LaporanCashInRoutes'
)

const { LaporanCashOutRoutes } = lazyImport(
  () => import('@features/laporan/cash-out'),
  'LaporanCashOutRoutes'
)

const { LaporanShipmentBelumBertarifRoutes } = lazyImport(
  () => import('@features/laporan/shipment-belum-bertarif'),
  'LaporanShipmentBelumBertarifRoutes'
)

const { LaporanShipmentTBNRoutes } = lazyImport(
  () => import('@features/laporan/shipment-TBN'),
  'LaporanShipmentTBNRoutes'
)

const { LaporanShipmentKSORoutes } = lazyImport(
  () => import('@features/laporan/shipment-kso'),
  'LaporanShipmentKSORoutes'
)

const { LaporanTonaseBLRoutes } = lazyImport(
  () => import('@features/laporan/tonase-bl'),
  'LaporanTonaseBLRoutes'
)
const { LaporanPengangkutanBatuBaraRoutes } = lazyImport(
  () => import('@features/laporan/pengangkutan-batubara'),
  'LaporanPengangkutanBatuBaraRoutes'
)

const { LaporanIdlePemasokRoutes } = lazyImport(
  () => import('@features/laporan/idle-pemasok'),
  'LaporanIdlePemasokRoutes'
)
const { LaporanShipmentPltuRoutes } = lazyImport(
  () => import('@features/laporan/shipment-per-pltu'),
  'LaporanShipmentPltuRoutes'
)
const { LaporanIdlePltuRoutes } = lazyImport(
  () => import('@features/laporan/idle-pltu'),
  'LaporanIdlePltuRoutes'
)

const { LaporanKelengkapanDokumenRoutes } = lazyImport(
  () => import('@features/laporan/kelengkapan-dokumen'),
  'LaporanKelengkapanDokumenRoutes'
)

const { ShiplinkRoutes } = lazyImport(
  () => import('@features/shiplink'),
  'ShiplinkRoutes'
)

const { FormulaRoutes } = lazyImport(
  () => import('@features/master-data/formula'),
  'FormulaRoutes'
)

const { HargaTriwulanRoutes } = lazyImport(
  () => import('@features/master-data/harga-triwulan'),
  'HargaTriwulanRoutes'
)

export const protectedRoutes: RouteObject[] = [
  {
    path: '/dashboard',
    element: <DashboardProvider />,
    children: [
      {
        path: 'dashboard/*',
        children: [
          {
            path: 'dashboard-penjualan/*',
            element: <DashboardPenjualanRoutes />,
          },
          { path: 'dashboard-niaga/*', element: <DashboardNiagaRoutes /> },
          { path: 'dashboard-bbo/*', element: <DashboardBBORoutes /> },
        ],
      },
      {
        path: 'master-data/*',
        children: [
          { path: 'moda/*', element: <ModaRoutes /> },
          { path: 'kapal-vessel/*', element: <KapalRoutes /> },
          { path: 'termin/*', element: <TerminRoutes /> },
          { path: 'pol/*', element: <PoLRoutes /> },
          { path: 'pemberi-kerja/*', element: <PemberiKerjaRoutes /> },
          { path: 'pod/*', element: <PoDRoutes /> },
          { path: 'route/*', element: <RouteRoutes /> },
          { path: 'pemasok/*', element: <PemasokRoutes /> },
          { path: 'mitra-kso/*', element: <MitraKSORoutes /> },
          { path: 'formula/*', element: <FormulaRoutes /> },
          { path: 'harga-triwulan/*', element: <HargaTriwulanRoutes /> },
          { path: '*', element: <PageNotFound /> },
        ],
      },
      {
        path: 'contract/*',
        children: [
          { path: 'kontrak-mitra-kso/*', element: <KontrakMitraKSORoutes /> },
          { path: 'pemberi-kerja/*', element: <KontrakPemberiKerjaRoutes /> },
          { path: '*', element: <PageNotFound /> },
        ],
      },
      { path: 'users/*', element: <UsersRoutes /> },
      { path: 'profile/*', element: <ProfileRoutes /> },
      {
        path: 'shipment-order/*',
        children: [
          { path: 'shipment/*', element: <ShipmentRoutes /> },
          { path: 'agenda-rakor/*', element: <AgendaRakorRoutes /> },
          {
            path: 'sailing-instruction/*',
            element: <SailingInstructionRoutes />,
          },
          { path: 'spal/*', element: <SPALRoutes /> },
          { path: '*', element: <PageNotFound /> },
        ],
      },
      {
        path: 'operation/*',
        children: [
          {
            path: 'shipment-operation/*',
            element: <ShipmentOperationRoutes />,
          },
          {
            path: 'arsip-operation/*',
            element: <ArchiveOperationRoutes />,
          },
          { path: '*', element: <PageNotFound /> },
        ],
      },
      {
        path: 'asuransi/*',
        children: [
          {
            path: 'klaim-asuransi/*',
            element: <InsuranceClaimRoutes />,
          },
          {
            path: 'list-insurance/*',
            element: <ShipmentInsuranceRoutes />,
          },
          { path: '*', element: <PageNotFound /> },
        ],
      },
      { path: 'bbm/*', element: <BbmRoutes /> },
      { path: 'pembayaran-mitra/*', element: <PembayaranMitraRoutes /> },
      {
        path: 'penagihan/*',
        element: <PenagihanMitraRoutes />,
        // children: [
        //   {
        //     path: 'pemberi-kerja/*',
        //     element: <PenagihanPemberiKerjaRoutes />,
        //   },
        //   {
        //     path: 'mitra/*',
        //     element: <PenagihanMitraRoutes />,
        //   },
        //   { path: '*', element: <PageNotFound /> },
        // ],
      },
      {
        path: 'laporan/*',
        children: [
          {
            path: 'pendapatan-batubara/*',
            element: <PendapatanBatuBaraRoutes />,
          },
          {
            path: 'rasio-operasional-kso/*',
            element: <RasioOperasionalRoutes />,
          },
          {
            path: 'shipment-tepat-waktu/*',
            element: <ShipmentTepatWaktuRoutes />,
          },
          {
            path: 'cash-in/*',
            element: <LaporanCashInRoutes />,
          },
          {
            path: 'cash-out/*',
            element: <LaporanCashOutRoutes />,
          },
          {
            path: 'shipment-belum-bertarif/*',
            element: <LaporanShipmentBelumBertarifRoutes />,
          },
          {
            path: 'shipment-tbn/*',
            element: <LaporanShipmentTBNRoutes />,
          },
          {
            path: 'shipment-kso/*',
            element: <LaporanShipmentKSORoutes />,
          },
          {
            path: 'tonase-bl/*',
            element: <LaporanTonaseBLRoutes />,
          },
          {
            path: 'idle-pemasok/*',
            element: <LaporanIdlePemasokRoutes />,
          },
          {
            path: 'pengangkutan-batubara/*',
            element: <LaporanPengangkutanBatuBaraRoutes />,
          },
          {
            path: 'kelengkapan-dokumen/*',
            element: <LaporanKelengkapanDokumenRoutes />,
          },
          { path: 'shipment-pltu/*', element: <LaporanShipmentPltuRoutes /> },
          {
            path: 'idle-pltu/*',
            element: <LaporanIdlePltuRoutes />,
          },
          {
            path: 'monitoring-sla/*',
            element: <MonitoringSlaRoutes />,
          },
          { path: '*', element: <PageNotFound /> },
        ],
      },
      {
        path: 'shiplink/*',
        element: <ShiplinkRoutes />,
        children: [],
      },
      { path: '', element: <DashboardRoutes /> },
      { path: '*', element: <PageNotFound /> },
    ],
  },
]
