import { getStatusAction } from '@utils/dashboard'

import { DashboardDefaultStatusprops } from '@elements/index'

export const DashboardDefaultStatus: React.FC<DashboardDefaultStatusprops> = (
  props
) => {
  const { isLoading, action, additionalAction, numLoading } = props
  if (!action) return <></>
  if (isLoading) {
    return (
      <>
        {Array.from({ length: 2 + (numLoading || 0) }, (x, i) => i).map(
          (elem) => {
            return (
              <div key={elem} className='animate-pulse space-y-1'>
                <div className='my-4 h-full w-1/2 bg-slate-200 p-3' />
                <div className='my-4 h-full w-full bg-slate-200 p-2' />
              </div>
            )
          }
        )}
      </>
    )
  }
  return (
    <>
      {[...getStatusAction(action), ...(additionalAction || [])].map(
        (val, idx) => {
          return (
            <div key={idx} className='flex flex-col space-y-1 text-gray-600'>
              <h2>{val.title}</h2>
              <span>
                {val.date} oleh {val.name}
              </span>
            </div>
          )
        }
      )}
    </>
  )
}
