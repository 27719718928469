import { getProperty } from '@utils/base'

import {
  InputLeftAddon,
  InputLeftElement,
  InputRightAddon,
  InputRightElement,
  InputSize,
  InputSizeMap,
} from '@forms/index'

import { InputGroupProps } from './InputGroup.types'

const paddingLeft: InputSizeMap<string> = {
  xs: '!pl-6',
  sm: '!pl-7',
  md: '!pl-8',
  lg: '!pl-10',
  xl: '!pl-12',
}

const paddingRight: InputSizeMap<string> = {
  xs: '!pr-6',
  sm: '!pr-7',
  md: '!pr-8',
  lg: '!pr-10',
  xl: '!pr-12',
}

export default function useInputGroupClass(
  props: InputGroupProps,
  element: React.ReactElement[]
) {
  const classes = {
    paddingLeft: '',
    paddingRight: '',
    roundedLeft: '',
    roundedRight: '',
  }

  element.forEach((child) => {
    if (child.type === InputLeftElement) {
      classes.paddingLeft = getProperty(
        paddingLeft,
        props?.size || InputSize.md
      )
    }
    if (child.type === InputRightElement) {
      classes.paddingRight = getProperty(
        paddingRight,
        props?.size || InputSize.md
      )
    }
    if (child.type === InputLeftAddon) {
      classes.roundedLeft = '!rounded-l-none'
    }
    if (child.type === InputRightAddon) {
      classes.roundedRight = '!rounded-r-none'
    }
  })

  return Object.values(classes)
}
