export const isExistPathChild = (select: string, item: string): boolean => {
  const availablePath = ['create', 'view', 'edit']
  const paths = select.replace(item, '').split('/').slice(1)
  const isValidParent =
    paths.length > 0 ? availablePath.indexOf(paths?.[0]) !== -1 : false
  return select === item || (select.startsWith(item) && isValidParent)
}

export const validFormId = (formId = ''): boolean => {
  const uuidV4 = new RegExp(
    /^[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-4[a-fA-F0-9]{3}-[8|9|aA|bB][a-fA-F0-9]{3}-[a-fA-F0-9]{12}$/i
  )
  if (!uuidV4.test(formId)) return false
  return true
}
