import { roleAction, roleMatriks } from '@constants/index'

import { createState } from '@libs/state'

import { AuthTypes } from '@features/auth'

const { setState, useState } = createState({
  auth: null as AuthTypes.UserAuthState,
})

export const useAuth = () => useState('auth')
export const setAuth = (val: AuthTypes.UserMeState) => {
  if (!val) return
  const role = roleMatriks[val.role]
  const navigation = Object.keys(role || {}).reduce((acc, val) => {
    if (role[val].includes(roleAction.read)) {
      acc.push(val)
    }
    return acc
  }, [] as string[])

  setState('auth', {
    user: val,
    navigation,
    role,
  })
}
