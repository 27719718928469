import clsx from 'clsx'
import id from 'date-fns/locale/id'
import { forwardRef } from 'react'
import DatePicker, { ReactDatePickerProps } from 'react-datepicker'
import { HiOutlineCalendar } from 'react-icons/hi'

import 'react-datepicker/dist/react-datepicker.css'

import { Input, InputGroup, InputRightAddon } from '@forms/index'

const CustomInputDatepicker = forwardRef((props: any, ref) => {
  return (
    <InputGroup>
      <Input {...props} ref={ref} disabled={props.disabled} />
      <InputRightAddon disabled={props.disabled}>
        <HiOutlineCalendar />
      </InputRightAddon>
    </InputGroup>
  )
})

CustomInputDatepicker.displayName = 'CustomInputDatepicker'

export function Datepicker(props: ReactDatePickerProps) {
  const { className, ...rest } = props

  return (
    <DatePicker
      locale={id}
      customInput={<CustomInputDatepicker />}
      className={clsx('w-full', className)}
      {...rest}
    />
  )
}

export default Datepicker
