import { format } from 'date-fns'

import { SelectValue } from '@/types/components'
import {
  BuildBreadcrumbs,
  DashboardBaseTypeDetail,
  DashboardFormType,
} from '@/types/dashboard'
import { UseAccess } from '@/types/hooks'
import {
  ActionStatus as ActionStatus,
  ActionStatusProps as ActionStatusProps,
  CallbackValuesMapper,
  KeyReplaceString,
} from '@/types/utils'

import { BreadcrumbsData } from '@elements/index'

import { isValidDate } from './base'

export const getDashboardType = (
  type: DashboardFormType
): DashboardBaseTypeDetail => {
  return {
    isEdit: type === DashboardFormType.edit,
    isCreate: type === DashboardFormType.create,
    isView: type === DashboardFormType.view,
    isDeviasi: type === DashboardFormType.deviasi,
    isAmandemen: type === DashboardFormType.amandemen,
  }
}

export const getDashboardTypeName = (
  type: DashboardBaseTypeDetail,
  prefix?: string
) => {
  const baseTitle = type.isCreate
    ? 'Create'
    : type.isEdit
    ? 'Edit'
    : type.isDeviasi
    ? 'Deviasi'
    : type.isAmandemen
    ? 'Amandemen'
    : 'Detail'
  return baseTitle + (prefix ? ` ${prefix}` : '')
}

export const routeConcat = (...routes: string[]) => {
  const route = routes.join('/').replace(/\/\//g, '/')
  return route.startsWith('/') ? route.slice(1) : route
}

export const getDashboardPath = (
  type: DashboardFormType,
  currentPath: string
) => {
  const route = currentPath.slice(1).split('/')
  const position = route.indexOf(type)
  const end = position === -1 ? route.length - 1 : position

  return {
    home: `/${route[0]}`,
    parent: `/${routeConcat(...route.slice(0, end))}`,
  }
}

export const buildBreadcrumbs = ({
  type,
  parentTitle,
  currentTitle,
  currentPath,
}: BuildBreadcrumbs): BreadcrumbsData[] => {
  const route = getDashboardPath(type, currentPath.pathname)
  return [
    {
      to: route.home,
      content: 'Home',
    },
    {
      to: route.parent,
      content: parentTitle,
    },
    {
      to: currentPath.pathname,
      content: currentTitle,
    },
  ]
}

export const getStatusAction = ({
  created_at: created_at,
  created_by: created_by,
  updated_at: updated_at,
  updated_by: updated_by,
}: ActionStatusProps): ActionStatus[] => {
  return [
    ...(created_at || created_by
      ? [
          {
            title: 'Tanggal Pembuatan',
            date: created_at,
            name: created_by,
          },
        ]
      : []),
    ...(updated_at || updated_by
      ? [
          {
            title: 'Tanggal Diubah',
            date: updated_at,
            name: updated_by,
          },
        ]
      : []),
  ]
}

// Mapper
export const objectValue = (obj?: SelectValue): string => {
  return obj?.value || ''
}

export const objectLabel = (obj?: SelectValue): string => {
  return obj?.label || ''
}

export const objectValueNull = (obj?: SelectValue): string | null => {
  return obj?.value || null
}

export const valuesMapper = <T, U>(
  values: T,
  callback: CallbackValuesMapper<T, U>
): U => {
  const response = { ...values } as T
  return callback(response)
}

export const dataToSelect = <T = SelectValue>(
  obj?: any,
  addKey = [] as KeyReplaceString[],
  keyValue = 'id',
  keyLabel = 'nama'
): T => {
  return {
    ...(addKey.length > 0
      ? addKey.reduce((acc, val) => {
          if (obj?.[val.key]) {
            acc = {
              ...acc,
              [val?.newKey || val.key]: obj?.[val.key],
            }
          }
          return acc
        }, {})
      : {}),
    label: (obj?.[keyLabel] || '') as string,
    value: (obj?.[keyValue] || '') as string,
  } as T
}

export const dataToSelectFormula = <T = SelectValue>(
  obj?: any,
  addKey = [] as KeyReplaceString[],
  keyValue = 'id',
  keyLabel = 'nama_formula'
): T => {
  return {
    ...(addKey.length > 0
      ? addKey.reduce((acc, val) => {
          if (obj?.[val.key]) {
            acc = {
              ...acc,
              [val?.newKey || val.key]: obj?.[val.key],
            }
          }
          return acc
        }, {})
      : {}),
    label: (obj?.[keyLabel] || '') as string,
    value: (obj?.[keyValue] || '') as string,
  } as T
}

export const validTypeAccess = (
  type: DashboardFormType,
  access: UseAccess
): boolean => {
  switch (type) {
    case DashboardFormType.view:
      return access.hasRead()
    case DashboardFormType.create:
      return access.hasCreate()
    case DashboardFormType.edit:
      return access.hasUpdate()
    case DashboardFormType.deviasi:
      return access.hasUpdate()
    case DashboardFormType.amandemen:
      return access.hasUpdateOwn()
    default:
      return false
  }
}

export const valueToDate = (value: string): Date | null => {
  if (value === '') return null
  const newDate = new Date(value)
  if (!isValidDate(newDate)) return null
  return newDate
}

export const dateFormat = (
  value: string,
  dateFormat: string,
  options?: {
    locale?: Locale
    weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6
    firstWeekContainsDate?: number
    useAdditionalWeekYearTokens?: boolean
    useAdditionalDayOfYearTokens?: boolean
  }
): string => {
  const newDate = new Date(value)
  if (!isValidDate(newDate)) return ''
  return format(newDate, dateFormat, options)
}
