import clsx from 'clsx'

import { getProperty } from '@utils/base'
import { createMemoClass } from '@utils/styles'

import { InputSize, InputSizeMap } from '@forms/index'

import {
  InputElementPlacement,
  InputElementPlacementMap,
  InputElementProps,
} from './InputElement.types'

const sizes: InputSizeMap<string> = {
  xs: 'xs',
  sm: 'sm',
  md: 'md',
  lg: 'lg',
  xl: 'xl',
}

const placements: InputElementPlacementMap<string> = {
  left: 'left-0',
  right: 'right-0',
}

export default createMemoClass((props: InputElementProps) => {
  return clsx(
    'input-element',
    getProperty(sizes, props?.size || InputSize.md),
    getProperty(placements, props?.placement || InputElementPlacement.left),
    props.disabledPointerEvents && 'pointer-events-none',
    props.className
  )
})
