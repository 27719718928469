import { useState } from 'react'

export const useNavigation = () => {
  const [collapsed, setCollapsed] = useState<boolean>(true)

  const toggleCollapsed = () => {
    setCollapsed((state) => !state)
  }
  return { collapsed, toggleCollapsed }
}
