import { useMutation } from '@tanstack/react-query'
import { AxiosProgressEvent, AxiosResponse } from 'axios'

import { BaseById } from '@/types/apis'

import axios from '@libs/axios'

import {
  DeleteFileDTO,
  DeleteFileOptions,
  DownloadFileDTO,
  DownloadFileOptions,
  UploadFileDTO,
  UploadFileFormDTO,
  UploadFileOptions,
} from './FileUpload.types'

const uploadFile = async ({
  file: item,
  progress,
  signal,
}: UploadFileFormDTO): Promise<AxiosResponse<UploadFileDTO>> => {
  const data = new FormData()
  data.append('file', item)
  return await axios
    .post('api/v1/minio', data, {
      signal,
      onUploadProgress(event: AxiosProgressEvent) {
        progress(Math.floor((event.loaded / (event?.total || 1)) * 98))
      },
    })
    .then((res) => {
      progress(100)
      return res
    })
    .catch((err) => {
      return err
    })
}

const useUploadFile = (props?: UploadFileOptions) => {
  return useMutation({
    mutationFn: uploadFile,
    ...props?.config,
  })
}

const downloadFile = async ({
  id,
  params,
  signal,
}: BaseById): Promise<AxiosResponse<DownloadFileDTO>> => {
  return await axios.get('api/v1/minio/' + id, {
    signal,
    params,
    responseType: 'blob',
  })
}

const useDownloadFile = (props?: DownloadFileOptions) => {
  return useMutation({
    mutationFn: downloadFile,
    ...props?.config,
  })
}

const deleteFile = async ({
  id,
  params,
  signal,
}: BaseById): Promise<AxiosResponse<DeleteFileDTO>> => {
  return await axios.delete('api/v1/minio/' + id, {
    signal,
    params,
  })
}

const useDeleteFile = (props?: DeleteFileOptions) => {
  return useMutation({
    mutationFn: deleteFile,
    ...props?.config,
  })
}

export {
  deleteFile,
  downloadFile,
  uploadFile,
  useDeleteFile,
  useDownloadFile,
  useUploadFile,
}
