import { Suspense, useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import { storageKey } from '@constants/index'

import { useAuth } from '@stores/auth'
import notification from '@stores/notification'

import { validAuth } from '@utils/base'
import { storage } from '@utils/storage'

import { DashboardLayout } from '@layouts/index'
import { LoadingScreen } from '@elements/index'

let hasNavigateBack = false
const DashboardProvider: React.FC = () => {
  const [auth] = useAuth()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const hasValidAuth = validAuth()
    if (!hasValidAuth.isValid && !hasNavigateBack) {
      hasNavigateBack = true
      notification.error(hasValidAuth.message)
      storage.removeItem(storageKey.authToken)
      navigate('/login', {
        state: {
          redirect: location.pathname,
        },
      })
    }
  }, [auth, location.pathname])

  return (
    <DashboardLayout>
      <Suspense fallback={<LoadingScreen reason='Get dashboard component' />}>
        <Outlet />
      </Suspense>
    </DashboardLayout>
  )
}

export default DashboardProvider
