import React, { forwardRef, useMemo } from 'react'

import { isExistPathChild } from '@utils/route'

import {
  NavigationItemMenu,
  NavigationItemMenuMapper,
  NavigationProps,
} from './Navigation.types'
import { NavigationMenu } from './NavigationMenu'

export const Navigation = forwardRef<HTMLHtmlElement, NavigationProps>(
  (props, ref): JSX.Element => {
    const { currentLocation, currentPath, access, items, collapsed, ...rest } =
      props

    const isEmptyLocation = currentLocation == ''
    const menuItem = useMemo((): NavigationItemMenu[] => {
      return items.map((val) => {
        const toMenu = currentPath + val.to
        const allowMenu = isExistPathChild(currentLocation, toMenu)
        const mappingChildren: NavigationItemMenuMapper = val?.children
          ? {
              ...val?.children.reduce(
                (acc, v) => {
                  const toSubMenu = toMenu + v.to
                  const isActive = toSubMenu.startsWith(currentLocation)
                  const allowSubMenu = isExistPathChild(
                    currentLocation,
                    toSubMenu
                  )
                  const childrenIsActive =
                    (toMenu.startsWith(currentLocation) &&
                      isEmptyLocation &&
                      (isActive || allowSubMenu)) ||
                    allowSubMenu
                  if (!acc.isActive) acc.isActive = childrenIsActive
                  if (v.access && v.access.length > 0) {
                    acc.access = [...acc.access, ...v.access]
                  }
                  acc.value.push({
                    ...v,
                    to: toSubMenu,
                    isActive: childrenIsActive,
                  })
                  return acc
                },
                {
                  value: [] as NavigationItemMenu[],
                  isActive: false as boolean,
                  access: [],
                } as NavigationItemMenuMapper
              ),
            }
          : ({} as NavigationItemMenuMapper)

        return {
          ...val,
          to: toMenu,
          access: [
            ...(val.access && val.access.length > 0 ? val.access : []),
            ...(mappingChildren?.access ? mappingChildren.access : []),
          ],
          isActive:
            mappingChildren?.isActive ||
            (toMenu.startsWith(currentLocation) &&
              isEmptyLocation &&
              allowMenu) ||
            allowMenu,
          children: mappingChildren.value,
        }
      }) as NavigationItemMenu[]
    }, [currentLocation, ref])

    return (
      <nav role='navigation' className='navigation' ref={ref} {...rest}>
        <ul>
          {menuItem.map((val, index) => {
            const hasAccess = (val?.access || []).reduce((acc, value) => {
              if (access.includes(value) && !acc) {
                acc = true
              }
              return acc
            }, false)
            if (!hasAccess) return ''
            return (
              <NavigationMenu
                menuIndex={index}
                key={val.to}
                contentData={val}
                collapsed={collapsed}
              />
            )
          })}
        </ul>
      </nav>
    )
  }
)

Navigation.displayName = 'Navigation'
