import {
  HiOutlineChartPie,
  HiOutlineClipboard,
  HiOutlineClipboardCheck,
  HiOutlineCurrencyDollar,
  HiOutlineDocumentDuplicate,
  HiOutlineDocumentText,
  HiOutlineHome,
  HiOutlineShieldCheck,
  HiOutlineTruck,
  HiOutlineUser,
} from 'react-icons/hi'
import {
  HiOutlineCircleStack,
  HiOutlineCog6Tooth,
  HiOutlineReceiptPercent,
  HiOutlineSquare2Stack,
} from 'react-icons/hi2'

import { moduleName } from '@constants/index'

const navigation = [
  {
    title: 'Home',
    to: '',
    icon: HiOutlineHome,
    access: [moduleName.dashboard],
  },
  {
    title: 'Bagshiplink',
    to: '/shiplink',
    icon: HiOutlineClipboardCheck,
    access: [moduleName.shiplink],
  },
  {
    title: 'Dashboard',
    to: '/dashboard',
    icon: HiOutlineChartPie,
    children: [
      {
        title: 'Dashboard Penjualan',
        to: '/dashboard-penjualan',
        access: [moduleName.dashboardPenjualan],
      },
      {
        title: 'Dashboard Niaga',
        to: '/dashboard-niaga',
        access: [moduleName.dashboardNiaga],
      },
      {
        title: 'Dashboard Shipment Operasi BBO',
        to: '/dashboard-bbo',
        access: [moduleName.dashboardBBO],
      },
    ],
  },
  {
    title: 'User Management',
    to: '/users',
    icon: HiOutlineUser,
    access: [moduleName.userManagement],
  },
  {
    title: 'Kontrak',
    to: '/contract',
    icon: HiOutlineDocumentText,
    children: [
      {
        title: 'Kontrak KSO',
        to: '/kontrak-mitra-kso',
        access: [moduleName.kontrakMitraKSO],
      },
      {
        title: 'Pemberi Kerja',
        to: '/pemberi-kerja',
        access: [moduleName.kontrakPemberiKerja],
      },
    ],
  },
  // {
  //   title: 'Route Shipment',
  //   to: '/route-shipment',
  //   icon: HiOutlineGlobeEuropeAfrica,
  //   access: [moduleName.mdRouteShipment],
  // },
  {
    title: 'Shipment Order',
    to: '/shipment-order',
    icon: HiOutlineClipboard,
    access: ['shipment-order'],
    children: [
      {
        title: 'Shipment',
        to: '/shipment',
        access: [moduleName.shipment],
      },
      {
        title: 'Agenda Rakor',
        to: '/agenda-rakor',
        access: [moduleName.agendaRakor],
      },
      {
        title: 'Sailing Instruction',
        to: '/sailing-instruction',
        access: [moduleName.sailingInstruction],
      },
      {
        title: 'SPAL',
        to: '/spal',
        access: [moduleName.spal],
      },
    ],
  },
  {
    title: 'Operation',
    to: '/operation',
    icon: HiOutlineTruck,
    access: [moduleName.operation],
    children: [
      {
        title: 'Shipment Operation',
        to: '/shipment-operation',
        access: [moduleName.shipmentOperation],
      },
      {
        title: 'Arsip Shipment',
        to: '/arsip-operation',
        access: [moduleName.shipmentOperation],
      },
    ],
  },
  {
    title: 'Asuransi',
    to: '/asuransi',
    icon: HiOutlineShieldCheck,
    access: [moduleName.asuransi],
    children: [
      {
        title: 'Klaim Asuransi',
        to: '/klaim-asuransi',
        access: [moduleName.klaimAsuransi],
      },
      {
        title: 'Daftar Asuransi',
        to: '/list-insurance',
        access: [moduleName.listAsuransi],
      },
    ],
  },

  {
    title: 'Penagihan',
    to: '/penagihan',
    icon: HiOutlineReceiptPercent,
    access: [moduleName.penagihanMitraKso],
    // children: [
    //   {
    //     title: 'Operasi Mitra',
    //     to: '/mitra',
    //     access: [moduleName.penagihanMitraKso],
    //   },
    //   {
    //     title: 'Operasi Sendiri',
    //     to: '/pemberi-kerja',
    //     access: [moduleName.penagihanPemberiKerja],
    //   },
    // ],
  },
  {
    title: 'Pembayaran Mitra',
    to: '/pembayaran-mitra',
    icon: HiOutlineCurrencyDollar,
    access: [moduleName.pembayaranMitra],
  },
  // {
  //   title: 'Nota Dinas',
  //   to: '/nota-dinas',
  //   icon: HiOutlineSquare2Stack,
  //   access: [moduleName.notaDinasPenagihan],
  // },
  {
    title: 'Master Data',
    to: '/master-data',
    icon: HiOutlineCircleStack,
    children: [
      {
        title: 'Pemberi Kerja',
        to: '/pemberi-kerja',
        access: [moduleName.mdPemberiKerja],
      },
      {
        title: 'Mitra KSO',
        to: '/mitra-kso',
        access: [moduleName.mdMitraKso],
      },
      {
        title: 'Termin',
        to: '/termin',
        access: [moduleName.mdTermin],
      },
      {
        title: 'Pemasok',
        to: '/pemasok',
        access: [moduleName.mdPemasok],
      },
      {
        title: 'Moda',
        to: '/moda',
        access: [moduleName.mdModa],
      },
      {
        title: 'PoL',
        to: '/pol',
        access: [moduleName.mdPol],
      },
      {
        title: 'PoD',
        to: '/pod',
        access: [moduleName.mdPod],
      },
      {
        title: 'Kapal/Vessel',
        to: '/kapal-vessel',
        access: [moduleName.mdKapal],
      },
      {
        title: 'Route',
        to: '/route',
        access: [moduleName.mdRouteShipment],
      },
      {
        title: 'Formula',
        to: '/formula',
        access: [moduleName.mdFormula],
      },
      {
        title: 'Harga Triwulan',
        to: '/harga-triwulan',
        access: [moduleName.mdHargaTriwulan],
      },
    ],
  },
  {
    title: 'BBM Berjalan',
    to: '/bbm',
    icon: HiOutlineSquare2Stack,
    access: [moduleName.bbmBerjalan],
  },
  {
    title: 'Pengaturan',
    to: '/settings',
    icon: HiOutlineCog6Tooth,
    access: ['setting'],
  },
  {
    title: 'Laporan',
    to: '/laporan',
    icon: HiOutlineDocumentDuplicate,
    children: [
      {
        title: 'Monitoring SLA',
        to: '/monitoring-sla',
        access: [moduleName.listAsuransi],
      },
      {
        title: 'Pendapatan Angkutan BatuBara',
        to: '/pendapatan-batubara',
        access: [moduleName.pendapatanBatuBara],
      },
      {
        title: 'Rasio Biaya Operasional KSO',
        to: '/rasio-operasional-kso',
        access: [moduleName.rasioOperasionalKso],
      },
      {
        title: 'Shipment Tepat Waktu',
        to: '/shipment-tepat-waktu',
        access: [moduleName.shipmentTepatWaktu],
      },
      {
        title: 'Penerimaan Cash Operasional - Cash In',
        to: '/cash-in',
        access: [moduleName.laporanCashIn],
      },
      {
        title: 'Realisasi Pembayaran Operasional KSO - Cash Out',
        to: '/cash-out',
        access: [moduleName.laporanCashOut],
      },
      {
        title: 'Route Shipment Belum Bertarif',
        to: '/shipment-belum-bertarif',
        access: [moduleName.laporanShipmentBelumBertarif],
      },
      {
        title: 'Shipment To Be Nominated (TBN)',
        to: '/shipment-tbn',
        access: [moduleName.laporanShipmentTBN],
      },
      {
        title: 'Status Shipment per Mitra KSO',
        to: '/shipment-kso',
        access: [moduleName.laporanShipmentKSO],
      },
      {
        title: 'Kinerja Angkut Mitra KSO (Tonase BL)',
        to: '/tonase-bl',
        access: [moduleName.laporanTonaseBL],
      },
      {
        title: 'Idle Time Armada Harian Per Pemasok',
        to: '/idle-pemasok',
        access: [moduleName.laporanIdlePemasok],
      },
      {
        title: 'Pengangkutan Batubara Per Pemasok',
        to: '/pengangkutan-batubara',
        access: [moduleName.laporanPengangkutanBatubara],
      },
      {
        title: 'Shipment Per PLTU',
        to: '/shipment-pltu',
        access: [moduleName.laporanPengangkutanBatubara],
      },
      {
        title: 'Idle Time Armada Harian Per PLTU',
        to: '/idle-pltu',
        access: [moduleName.laporanIdlePltu],
      },
      {
        title: 'Kelengkapan Dokumen Shipment',
        to: '/kelengkapan-dokumen',
        access: [moduleName.laporanKelengkapanDokumen],
      },
    ],
  },
]

export { navigation }
