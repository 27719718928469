import clsx from 'clsx'
import * as React from 'react'

import { getValidChildren } from '@utils/base'

import { Input } from '@forms/index'

import useInputGroupClass from './InputGroup.styles'
import { InputGroupProps } from './InputGroup.types'

export const InputGroup = React.forwardRef<HTMLDivElement, InputGroupProps>(
  (props, ref) => {
    const { children, className, size = 'md', ...rest } = props

    const validChildren = getValidChildren(children)
    const classes = useInputGroupClass(props, validChildren)

    return (
      <div
        ref={ref}
        role='group'
        className={clsx('input-group', className)}
        {...rest}
      >
        {validChildren.map((child) => {
          if (child.type === Input) {
            return React.cloneElement(child, {
              size,
              className: clsx(classes, child.props.className),
            })
          }
          return React.cloneElement(child, { size })
        })}
      </div>
    )
  }
)

InputGroup.displayName = 'InputGroup'
