import { FormikHelpers } from 'formik'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { ValidToken } from '@/types/utils'

import notification from '@stores/notification'

import { getErrorMessage, getMessage } from '@utils/apis'

import { useResetPassword as useResetPasswordService } from '../services'
import { ResetPasswordFormDTO } from '../types'

export const validToken = (tokenKey: string): ValidToken => {
  if (!tokenKey) {
    return {
      isValid: false,
      message: 'Token reset password tidak ada',
    }
  }
  try {
    const token = JSON.parse(atob(tokenKey.split('.')[1]))
    if (token.exp * 1000 < Date.now()) {
      return {
        isValid: false,
        message: 'Token reset password login sudah habis',
      }
    }
    return {
      isValid: true,
      message: 'Token valid',
    }
  } catch (e) {
    return {
      isValid: false,
      message: 'Token tidak valid',
    }
  }
}

let hasNavigateBack = false
const useResetPassword = () => {
  const navigate = useNavigate()
  const { token } = useParams()

  const formValue: ResetPasswordFormDTO = {
    password: '',
    password_confirmation: '',
  }

  useEffect(() => {
    const checkToken = validToken(token || '')
    if (!checkToken.isValid && !hasNavigateBack) {
      hasNavigateBack = true
      notification.error(checkToken.message)
      navigate('/login', {
        replace: true,
      })
    }
  }, [])

  const resetPassword = useResetPasswordService()
  const handleSubmit = async (
    values: ResetPasswordFormDTO,
    formikHelpers: FormikHelpers<ResetPasswordFormDTO>
  ) => {
    await resetPassword
      .mutateAsync({
        id: token || '',
        data: values,
      })
      .then((res) => {
        notification.success(getMessage(res))
        navigate('/login', {
          replace: true,
        })
      })
      .catch((err) => {
        notification.error(getErrorMessage(err))
        if (err.code !== 400) {
          navigate('/login', {
            replace: true,
          })
        }
      })
      .finally(() => {
        formikHelpers.setSubmitting(false)
      })
  }

  return {
    formValue,
    handleSubmit,
  }
}

export default useResetPassword
