import React from 'react'

import useInputElementClass from './InputElement.styles'
import { InputElementProps } from './InputElement.types'

const InputElement: React.FC<InputElementProps> = ({
  placement,
  size,
  className,
  children,
  ...props
}) => {
  const classes = useInputElementClass({ size, placement, className })

  return (
    <div className={classes} {...props}>
      {children}
    </div>
  )
}

InputElement.displayName = 'InputElement'

export const InputLeftElement: React.FC<InputElementProps> = (props) => (
  <InputElement placement='left' {...props} />
)

InputLeftElement.displayName = 'InputLeftElement'

export const InputRightElement: React.FC<InputElementProps> = (props) => (
  <InputElement placement='right' {...props} />
)

InputRightElement.displayName = 'InputRightElement'
