import React, { forwardRef } from 'react'

import useBadgeClass from './Badge.styles'
import { BadgeProps } from './Badge.types'

export const Badge = forwardRef<HTMLElement, BadgeProps>((props, ref) => {
  const { size, variant, className, children, ...rest } = props

  const classes = useBadgeClass({ size, variant, className })

  return (
    <span ref={ref} className={classes} {...rest}>
      {children}
    </span>
  )
})

Badge.displayName = 'Badge'
