import { CustomDashboardStatus } from '@elements/index'

import { DashboardStatusProps } from './Dashboard.types'
import { DashboardDefaultStatus } from './DashboardDefaultStatus'

export const DashboardStatus: React.FC<DashboardStatusProps> = (props) => {
  const { pageType, isLoading, status, action } = props

  if (pageType.isCreate || !action) return <></>
  return (
    <CustomDashboardStatus isLoading={isLoading} status={status}>
      <DashboardDefaultStatus isLoading={isLoading} action={action} />
    </CustomDashboardStatus>
  )
}
