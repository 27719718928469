import clsx from 'clsx'
import { useEffect, useState } from 'react'

import { DashboardListStatusProps, ListMapStatus } from './Dashboard.types'

export const DashboardListStatus: React.FC<DashboardListStatusProps> = (
  props
) => {
  const { current, listStatus, className, isLoading, ...rest } = props
  const [listMapStatus, setListMapStatus] = useState<ListMapStatus[]>([])

  useEffect(() => {
    const newListStatus = listStatus
      .reverse()
      .reduce(
        (acc, val) => {
          if (val.key === current) {
            acc.hasActive = true
          }
          acc.data.push({
            isActive: acc.hasActive,
            key: val.key,
            name: val.name,
          })
          return acc
        },
        {
          hasActive: false,
          data: [] as ListMapStatus[],
        }
      )
      .data.reverse()
    setListMapStatus(newListStatus)
  }, [current])

  return (
    <div className={clsx('wrapper-list-status', className)} {...rest}>
      {isLoading
        ? listMapStatus.map((val, idx) => {
            return (
              <div key={idx} className='list-status animate-pulse'>
                <div className='h-8 w-8 rounded-full bg-slate-200 p-2' />
                <div className='h-full w-full bg-slate-200 p-2' />
              </div>
            )
          })
        : listMapStatus.map((val, idx) => {
            return (
              <div
                key={idx}
                className={clsx('list-status', val.isActive && 'active')}
              >
                <span>{idx + 1}</span>
                <h3>{val.name}</h3>
              </div>
            )
          })}
    </div>
  )
}
