import React from 'react'

export type BadgeProps = Omit<React.HTMLProps<HTMLSpanElement>, 'size'> &
  IBadgeProps

export interface IBadgeProps {
  variant?: BadgeVariant
  size?: BadgeSize
  className?: string
  children?: React.ReactNode
}

export const BadgeSize = {
  md: 'md',
  lg: 'lg',
} as const

export type BadgeSize = (typeof BadgeSize)[keyof typeof BadgeSize]

export type BadgeSizeMap<T> = {
  [size in keyof typeof BadgeSize]: T
}

export const BadgeVariant = {
  default: 'default',
  defaultDark: 'defaultDark',
  success: 'success',
  successDark: 'successDark',
  danger: 'danger',
  dangerDark: 'dangerDark',
  warning: 'warning',
  warningDark: 'warningDark',
  info: 'info',
  infoDark: 'infoDark',
  infoFill: 'infoFill',
  successFill: 'successFill',
  lightWarning: 'lightWarning',
  error: 'error',
} as const

export type BadgeVariant = (typeof BadgeVariant)[keyof typeof BadgeVariant]

export type BadgeVariantMap<T> = {
  [variant in keyof typeof BadgeVariant]: T
}
