import clsx from 'clsx'
import { useState } from 'react'
import { AsyncPaginate } from 'react-select-async-paginate'

import { BaseApis } from '@/types/apis'

import { mitraIsBAg } from '@constants/index'

import { emptyValueLabel } from '@utils/components'

import { DropdownIndicator, selectStyles } from './Select'
import { SelectPaginationProps } from './SelectPagination.types'

export const SelectPagination = <T extends BaseApis<any>, U, V>(
  props: SelectPaginationProps<T, U, V>
) => {
  const {
    className,
    perPage = 10,
    debounceTimeout = 1000,
    searchKey = 'search',
    valueKey = 'id',
    labelKey = 'nama',
    isAllData = false,
    apiController,
    query,
    cacheUniqs,
    value,
    customOptions,
    ...rest
  } = props

  const fetchApi = apiController()
  const [search, setSearch] = useState<string>('')
  const queryData = {
    ...(searchKey !== '' && search !== '' ? { [searchKey]: search } : {}),
    ...query,
  }

  const loadOptions = async (search: string, prev: any, more: any) => {
    setSearch(search)
    const page = more?.page || 1
    const getData = await fetchApi
      .mutateAsync({
        params: {
          limit: perPage,
          page,
          ...(searchKey !== '' && search !== '' ? { [searchKey]: search } : {}),
          ...query,
        },
      } as V)
      .catch(() => {
        return {} as BaseApis<any>
      })

    const totalData = getData?.metadata?.totalData || 0
    let resultData = (getData?.data || []).map((val: any) => {
      return {
        ...(isAllData ? val : {}),
        value: val?.[valueKey],
        label: val?.[labelKey],
      }
    })
    if (customOptions && page < 2) {
      switch (customOptions) {
        case 'mitra_bag':
          resultData = [mitraIsBAg, ...resultData]
          // console.log(resultData)
          break
        default:
          break
      }
    }
    return {
      options: resultData,
      hasMore: totalData > perPage * page,
      additional: {
        page: page + 1,
      },
    }
  }

  const cacheKeys: ReadonlyArray<any> = cacheUniqs || Object.values(queryData)
  const newValue = emptyValueLabel(value)
  return (
    <AsyncPaginate
      value={newValue}
      loadOptions={loadOptions}
      components={{ DropdownIndicator }}
      styles={selectStyles}
      debounceTimeout={debounceTimeout}
      className={clsx('w-full', className)}
      cacheUniqs={cacheKeys}
      {...rest}
    />
  )
}

export default SelectPagination
