import bannerImage from '@images/kapal_login.jpg'

import { BaseLayoutProps } from '@/types/components'

const AuthLayouts: React.FC<BaseLayoutProps> = ({ children }) => {
  return (
    <div className='relative flex min-h-screen'>
      <div className='flex min-w-0 flex-auto flex-row items-center justify-center bg-white md:items-start md:justify-start'>
        <div className='w-auto bg-white p-8 sm:rounded-lg md:flex md:h-full md:items-center  md:justify-center md:rounded-none md:p-10 lg:p-14 xl:w-2/5'>
          {children}
        </div>
        <div className='relative hidden h-full flex-auto items-center justify-center overflow-hidden bg-blue-500 p-10 text-white sm:w-1/2 md:flex xl:w-3/5'>
          <img
            src={bannerImage}
            className='absolute h-full w-full object-cover'
            alt='Banner'
          />
        </div>
      </div>
    </div>
  )
}

export default AuthLayouts
