import clsx from 'clsx'
import { ErrorMessage } from 'formik'
import React, { createContext, forwardRef, useContext, useId } from 'react'

import { getProperty } from '@utils/base'

import { FormError } from '@forms/index'

import {
  FormControlProps,
  UseFormControlData,
  UseFormControlProps,
} from './FormControl.types'

export const useFormControl = (
  props: UseFormControlProps
): UseFormControlData => {
  const context = useFormControlContext()
  if (!context) {
    return props
  }
  const keys = Object.keys(context)
  return keys.reduce<UseFormControlData>((acc, prop: string) => {
    const getProp = prop as keyof UseFormControlData & undefined
    acc[getProp] = getProperty(props, getProp)
    if (context) {
      if (props[getProp] == null) {
        acc[getProp] = getProperty(context, getProp)
      }
    }
    return acc
  }, {} as UseFormControlData)
}

const FormControlContext = createContext<UseFormControlProps | undefined>(
  undefined
)

const useFormControlContext = () => useContext(FormControlContext)

export const FormControl = forwardRef<HTMLDivElement, FormControlProps>(
  (props, ref) => {
    const {
      children,
      className,
      required,
      disabled,
      invalid,
      readOnly,
      isHorizontal,
      id: idProp,
      error,
      errorKey,
      ...rest
    } = props

    const classes = clsx('form-control', className)

    const idx = useId()
    const id = idProp || `field-${idx}`
    const labelId = `${id}-label`
    const errorId = `${id}-error`
    const helpTextId = `${id}-helptext`

    const context = {
      required,
      disabled,
      invalid: invalid || !!error,
      isHorizontal,
      readOnly,
      id,
      labelId,
      errorId,
      helpTextId,
    }

    return (
      <FormControlContext.Provider value={context}>
        <div role='group' ref={ref} className={classes} {...rest}>
          <div className={isHorizontal ? 'horizontal' : ''}>{children}</div>
          {!!error && (
            <FormError className={isHorizontal ? 'horizontal' : ''}>
              {error}
            </FormError>
          )}
          {!!errorKey && (
            <FormError className={isHorizontal ? 'horizontal' : ''}>
              <ErrorMessage name={errorKey} />
            </FormError>
          )}
        </div>
      </FormControlContext.Provider>
    )
  }
)

FormControl.displayName = 'FormGroup'
