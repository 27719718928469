import { useMutation } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'

import { BaseCreateForm } from '@/types/apis'

import axios from '@libs/axios'

import {
  ForgotPasswordDTO,
  ForgotPasswordFormDTO,
  ForgotPasswordOptions,
} from '../types'

const forgotPassword = async ({
  data,
  signal,
}: BaseCreateForm<ForgotPasswordFormDTO>): Promise<
  AxiosResponse<ForgotPasswordDTO>
> => {
  return await axios.post('api/v1/auth/forgot-password', data, {
    signal,
  })
}

const useForgotPassword = (props?: ForgotPasswordOptions) => {
  return useMutation({
    mutationFn: forgotPassword,
    ...props?.config,
  })
}

export { forgotPassword, useForgotPassword }
