import clsx from 'clsx'
import React, { forwardRef } from 'react'

import { RequiredIndicator, useFormControl } from '@forms/index'

import { FormLabelProps } from './FormLabel.types'

export const FormLabel = forwardRef<HTMLLabelElement, FormLabelProps>(
  (props, ref) => {
    const { children, className, htmlFor, id, ...rest } = props
    const formControl = useFormControl(rest)
    const classes = clsx(
      'form-label',
      formControl.disabled && 'disabled',
      formControl.isHorizontal && 'horizontal',
      className
    )

    return (
      <label
        ref={ref}
        className={classes}
        htmlFor={htmlFor || formControl.id}
        id={id || formControl.labelId}
        {...rest}
      >
        {children}
        {formControl.required && <RequiredIndicator />}
      </label>
    )
  }
)

FormLabel.displayName = 'FormLabel'
