import { useRoutes } from 'react-router-dom'

// import MaintenancePage from '@elements/mistake/Maintenance'
import { PageNotFound } from '@elements/index'

import { protectedRoutes } from './protected'
import { publicRoutes } from './public'

export const AppRoutes = (): JSX.Element => {
  const element = useRoutes([
    ...protectedRoutes,
    ...publicRoutes,
    {
      path: '*',
      element: <PageNotFound />,
    },
    /* For Maintenance
    {
      path: '*',
      element: <MaintenancePage _message='xxx' />,
    },
    */
  ])
  return <>{element}</>
}

export default AppRoutes
