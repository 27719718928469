import React from 'react'

export type AvatarProps = Omit<React.HTMLProps<HTMLDivElement>, 'size'> &
  IAvatarProps

export interface IAvatarProps {
  src?: string
  alt?: string
  size?: AvatarSize
}

export const AvatarSize = {
  xs: 'xs',
  sm: 'sm',
  md: 'md',
  lg: 'lg',
  xl: 'xl',
} as const

export type AvatarSize = (typeof AvatarSize)[keyof typeof AvatarSize]

export type AvatarSizeMap<T> = {
  [size in keyof typeof AvatarSize]: T
}
