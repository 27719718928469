import {
  FloatingFocusManager,
  FloatingOverlay,
  FloatingPortal,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react-dom-interactions'
import clsx from 'clsx'
import React, { createContext, forwardRef, useContext } from 'react'

import { mergeRefs } from '@utils/refs'

import useModalClass from './Modal.styles'
import { IModalProps, ModalProps } from './Modal.types'

const ModalContext = createContext<IModalProps>({} as IModalProps)

export const useModalContext = () => useContext(ModalContext)

export const Modal = forwardRef<HTMLDivElement, ModalProps>((props, ref) => {
  const {
    isOpen,
    handler,
    dismiss,
    isLoading,
    isScrollable,
    children,
    size,
    className,
    ...rest
  } = props

  const { floating, context } = useFloating({
    open: isOpen,
    onOpenChange: handler,
  })

  const { getFloatingProps } = useInteractions([
    useClick(context),
    useRole(context),
    useDismiss(context, dismiss),
  ])

  const mergedRef = React.useMemo(
    () => mergeRefs([ref, floating]),
    [floating, ref]
  )

  const classes = useModalClass({ size, className })
  const modalContext = {
    isScrollable,
    isOpen,
    handler,
    dismiss,
    isLoading,
    children,
    size,
  }

  return (
    <ModalContext.Provider value={modalContext}>
      <FloatingPortal>
        {isOpen && (
          <FloatingOverlay
            style={{
              zIndex: 48,
            }}
            lockScroll
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          >
            <FloatingFocusManager context={context}>
              <div
                className={clsx(
                  'modal-container',
                  isScrollable && 'scrollable'
                )}
              >
                <div
                  className={clsx(
                    'modal-overlay',
                    dismiss?.enabled &&
                      dismiss?.outsidePress &&
                      'cursor-pointer'
                  )}
                  aria-hidden='true'
                />
                <div
                  {...getFloatingProps({
                    ...rest,
                    ref: mergedRef,
                    className: classes,
                  })}
                >
                  {children}
                </div>
              </div>
            </FloatingFocusManager>
          </FloatingOverlay>
        )}
      </FloatingPortal>
    </ModalContext.Provider>
  )
})

Modal.displayName = 'Modal'
