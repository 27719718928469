import clsx from 'clsx'
import { HiOutlineChevronUpDown } from 'react-icons/hi2'
import ReactSelect, { Props, StylesConfig } from 'react-select'

import { emptyValueLabel } from '@utils/components'

const selectStyles: StylesConfig = {
  option: (style, { isDisabled, isFocused, isSelected }) => {
    const getBackgroundColor = () => {
      if (isDisabled) return undefined
      if (isSelected) return '#3b82f6'
      if (isFocused) return '#dbeafe'
      return undefined
    }

    return {
      ...style,
      color: isSelected ? '#fff' : style.color,
      backgroundColor: getBackgroundColor(),
      '&:hover': {
        color: '#000',
        backgroundColor: '#dbeafe',
      },
      '&:active': {
        color: '#000',
        backgroundColor: '#93c5fd',
      },
    }
  },
  control: (style, { isFocused }) => ({
    ...style,
    boxShadow: isFocused ? '0 0 0 1px #3b82f6' : '',
    borderColor: isFocused ? '#3b82f6' : '#CED4DA',
    '&:hover': {
      borderColor: isFocused ? '#3b82f6' : '#CED4DA',
    },
  }),
  multiValue: (style) => {
    return {
      ...style,
      backgroundColor: '#3b82f6',
    }
  },
  multiValueLabel: (style) => ({
    ...style,
    color: '#fff',
  }),
  multiValueRemove: (style) => ({
    ...style,
    color: '#fff',
    ':hover': {
      backgroundColor: '#2563eb',
      color: '#fff',
    },
  }),
}

const DropdownIndicator = () => {
  return (
    <div className='px-2'>
      <HiOutlineChevronUpDown className='h-5 w-5 text-gray-500' />
    </div>
  )
}

const Select: React.FC<Props> = (props) => {
  const { className, value, ...rest } = props
  const newValue = emptyValueLabel(value)
  return (
    <ReactSelect
      value={newValue}
      components={{ DropdownIndicator }}
      styles={selectStyles}
      className={clsx('w-full', className)}
      {...rest}
    />
  )
}

export default Select

export { DropdownIndicator, Select, selectStyles }
