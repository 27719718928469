import clsx from 'clsx'
import { nanoid } from 'nanoid'
import { forwardRef, useMemo } from 'react'
import { HiOutlineChevronLeft, HiOutlineChevronRight } from 'react-icons/hi'

import {
  PaginationProps,
  UsePaginationArray,
  UsePaginationProps,
} from './Pagination.types'

export const DOTS = '...'

const rangePagination = (start: number, end: number): number[] => {
  const length = end - start + 1
  return Array.from({ length }, (_, idx) => idx + start)
}

export const usePagination = ({
  totalCount,
  pageSize,
  siblingCount,
  currentPage,
}: UsePaginationProps): UsePaginationArray => {
  const paginationRange = useMemo((): UsePaginationArray => {
    const totalPageCount = Math.ceil(totalCount / pageSize)
    const totalPageNumbers = siblingCount + 5
    if (totalPageNumbers >= totalPageCount) {
      return rangePagination(1, totalPageCount)
    }

    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1)
    const rightSiblingIndex = Math.min(
      currentPage + siblingCount,
      totalPageCount
    )

    const shouldShowLeftDots = leftSiblingIndex > 2
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2

    const firstPageIndex = 1
    const lastPageIndex = totalPageCount

    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftItemCount = 3 + 2 * siblingCount
      const leftRange = rangePagination(1, leftItemCount)

      return [...leftRange, DOTS, totalPageCount]
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightItemCount = 3 + 2 * siblingCount
      const rightRange = rangePagination(
        totalPageCount - rightItemCount + 1,
        totalPageCount
      )
      return [firstPageIndex, DOTS, ...rightRange]
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      const middleRange = rangePagination(leftSiblingIndex, rightSiblingIndex)
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex]
    }

    return []
  }, [totalCount, pageSize, siblingCount, currentPage])

  return paginationRange
}

export const Pagination = forwardRef<HTMLUListElement, PaginationProps>(
  (props, ref) => {
    const {
      onPageChange,
      totalCount,
      siblingCount = 1,
      currentPage,
      pageSize,
      className,
      ...rest
    } = props

    const paginationRange = usePagination({
      currentPage,
      totalCount,
      siblingCount,
      pageSize,
    })

    if (currentPage === 0 || paginationRange.length < 2) {
      return null
    }

    const onNext = () => {
      onPageChange?.(currentPage + 1)
    }

    const onPrevious = () => {
      onPageChange?.(currentPage - 1)
    }

    const lastPage = paginationRange[paginationRange.length - 1]
    return (
      <ul ref={ref} className={clsx('pagination', className)} {...rest}>
        <li>
          <button
            type='button'
            className={clsx(
              'page rounded-l-md',
              currentPage === 1 && 'disabled'
            )}
            onClick={onPrevious}
            disabled={currentPage === 1}
          >
            <HiOutlineChevronLeft className='h-5 w-5' />
          </button>
        </li>
        {paginationRange.map((pageNumber) => {
          if (pageNumber === DOTS) {
            return (
              <li key={nanoid()}>
                <div role='presentation' className='dot'>
                  {DOTS}
                </div>
              </li>
            )
          }
          return (
            <li key={nanoid()}>
              <button
                type='button'
                className={clsx(
                  'page',
                  pageNumber === currentPage && 'disabled active'
                )}
                onClick={() => onPageChange?.(pageNumber as number)}
                disabled={pageNumber === currentPage}
              >
                {pageNumber}
              </button>
            </li>
          )
        })}
        <li>
          <button
            type='button'
            className={clsx(
              'page rounded-r-md',
              currentPage === lastPage && 'disabled'
            )}
            onClick={onNext}
            disabled={currentPage === lastPage}
          >
            <HiOutlineChevronRight className='h-5 w-5' />
          </button>
        </li>
      </ul>
    )
  }
)

Pagination.displayName = 'Pagination'
