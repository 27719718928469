import { Location } from 'react-router-dom'

import { BadgeVariant } from '@elements/badge'

export interface DashboardFormProps {
  type: DashboardFormType
}

export const DashboardFormType = {
  view: 'view',
  create: 'create',
  edit: 'edit',
  deviasi: 'deviasi',
  amandemen: 'amandemen',
} as const

export type DashboardFormType =
  (typeof DashboardFormType)[keyof typeof DashboardFormType]

export interface BuildBreadcrumbs {
  type: DashboardFormType
  parentTitle: string
  currentTitle: string
  currentPath: Location
}

export interface DashboardBaseTypeDetail {
  isEdit: boolean
  isCreate: boolean
  isView: boolean
  isDeviasi: boolean
  isAmandemen: boolean
}

export interface DashboardStatusType {
  variant: BadgeVariant
  value: string
}
