import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table'
import { AxiosError } from 'axios'
import clsx from 'clsx'
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import {
  HiDotsHorizontal,
  HiOutlineSortAscending,
  HiOutlineSortDescending,
} from 'react-icons/hi'
import {
  HiOutlineArchiveBoxXMark,
  HiOutlineChevronUpDown,
} from 'react-icons/hi2'
import { useLocation, useNavigate } from 'react-router-dom'

import { BaseApis } from '@/types/apis'

import { useDebounce } from '@hooks/useDebounce'

import { getTableLastCurrentParams, setTableLastParams } from '@utils/base'

import {
  Button,
  ButtonLink,
  Dropdown,
  DropdownHandler,
  DropdownMenu,
  Pagination,
} from '@elements/index'

import { ParamsToStore, TableClickRow, TableProps } from './Table.types'

export const Table = <T extends BaseApis<any>, U>(props: TableProps<T, U>) => {
  const {
    columns,
    keyId = 'id',
    perPage = 10,
    debounceSorting = 400,
    hasAutoNumber = false,
    onClickRow,
    apiController,
    actions,
    customActions,
    tableRef,
    query,
    access,
    isDetail,
    defaultSort,
    getDataCallback,
    isNoActionDetail = false,
    ...rest
  } = props
  const location = useLocation().pathname
  const paramsFromStorage = getTableLastCurrentParams(location)
  const [currentPage, setCurrentPage] = useState(paramsFromStorage.currentPage)
  const [sorting, setSorting] = React.useState<SortingState>(
    paramsFromStorage.sorting
  )

  const sortingMapping = useCallback(() => {
    if (sorting.length < 1) return
    // return encodeURI(`${sorting[0].id} ${sorting[0].desc ? 'desc' : 'asc'}`)
    return `${sorting[0].id} ${sorting[0].desc ? 'desc' : 'asc'}`
  }, [sorting])

  const { data, isFetching, isError, error, refetch, isLoading } =
    apiController({
      params: {
        page: currentPage,
        limit: perPage,
        ...(query || {}),
        sort: useDebounce(sortingMapping, debounceSorting) || defaultSort,
      },
    } as U)

  const storeCurrentParams = () => {
    const dataToStore: ParamsToStore = {
      currentPage: currentPage,
      pathname: location,
      query: query,
      sorting: sorting,
    }
    setTableLastParams(dataToStore)
  }
  useEffect(() => {
    getDataCallback && getDataCallback(data)
  }, [data])

  useImperativeHandle(tableRef, () => ({
    update: () => {
      refetch()
    },
  }))

  const navigate = useNavigate()
  const handleClickRow = (props: TableClickRow) => {
    if (onClickRow) {
      storeCurrentParams()
      onClickRow(props)
      return
    }
    if (!isNoActionDetail) navigate('view/' + props.id)
  }

  const getError = error as AxiosError<T, any> | null
  const pageCount = data?.metadata?.totalData || 0
  const content = data?.data || []
  const hasAction = actions?.hasView || actions?.hasEdit || customActions

  const memoizedData = useMemo(() => {
    if (!hasAutoNumber && !hasAction) return data
    return content.map((val: any, idx: number) => ({
      ...val,
      ...(hasAutoNumber && {
        autoNumber: idx + 1 + (currentPage - 1) * perPage,
      }),
    }))
  }, [content])

  const hasEdit = access?.hasUpdate() || false
  const memoizedColumns = useMemo(
    () => [
      ...(hasAutoNumber
        ? [
            {
              accessorKey: 'autoNumber',
              header: 'No',
            },
          ]
        : []),
      ...columns,
      ...(hasAction
        ? [
            {
              header: 'Action',
              cell: (value: any) => {
                const idx = value?.row?.original?.[keyId] || ''
                return (
                  <Dropdown placement='bottom-end'>
                    <DropdownHandler
                      onClick={(event) => {
                        storeCurrentParams()
                        event.stopPropagation()
                      }}
                    >
                      <Button variant='outline' type='button'>
                        <HiDotsHorizontal className='h-5 w-5' />
                      </Button>
                    </DropdownHandler>
                    <DropdownMenu
                      onClick={(event) => {
                        event.stopPropagation()
                      }}
                    >
                      <div className='dropdown-menu-container action-table w-22 mt-2'>
                        {actions?.hasView && (
                          <ButtonLink
                            to={'view/' + idx}
                            onClick={() => storeCurrentParams()}
                            variant='ghost'
                            size='sm'
                          >
                            View
                          </ButtonLink>
                        )}
                        {actions?.hasEdit && hasEdit && (
                          <ButtonLink
                            to={'edit/' + idx}
                            onClick={() => storeCurrentParams()}
                            variant='ghost'
                            size='sm'
                          >
                            Edit
                          </ButtonLink>
                        )}
                        {customActions?.(idx, value)}
                      </div>
                    </DropdownMenu>
                  </Dropdown>
                )
              },
            },
          ]
        : []),
    ],
    [columns]
  )

  const { getHeaderGroups, getRowModel, getAllColumns } = useReactTable({
    data: memoizedData,
    columns: memoizedColumns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    defaultColumn: {
      enableSorting: false,
    },
    manualPagination: true,
    pageCount,
    manualSorting: true,
  })

  const noDataFound =
    !isFetching && (!memoizedData || memoizedData.length === 0)
  const skeletons = Array.from({ length: perPage }, (x, i) => i)
  const isDetailScreen = isDetail || false

  return (
    <div
      className='flex flex-col overflow-hidden rounded-md bg-white'
      {...rest}
    >
      <div
        className={clsx('table-view relative', isDetailScreen && 'is-detail')}
      >
        {noDataFound || (isError && !isLoading) ? (
          <div
            className={clsx(
              'table-view flex w-full flex-col items-center justify-center border-b border-b-gray-200 text-gray-500',
              isDetailScreen && 'is-detail'
            )}
          >
            <HiOutlineArchiveBoxXMark className='h-12 w-12' />
            <h3 className='mt-1 font-semibold'>
              {isError
                ? getError?.response?.data?.message || getError?.message
                : 'No Data Found'}
            </h3>
          </div>
        ) : (
          <div
            className={clsx(
              'table-view overflow-x-auto border-b border-b-gray-200',
              isDetailScreen && 'is-detail'
            )}
          >
            <table className='min-w-full divide-y divide-gray-200'>
              <thead>
                {getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <td
                        key={header.id}
                        className={clsx(
                          'whitespace-nowrap px-6 py-3 text-left text-base font-bold tracking-wider',
                          isDetail && 'is-detail'
                        )}
                      >
                        {header.isPlaceholder ? null : (
                          <div
                            className={clsx([
                              'flex items-center justify-between',
                              header.column.getCanSort() &&
                                'cursor-pointer select-none',
                            ])}
                            role='presentation'
                            {...{
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            <span>
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                            </span>
                            <div>
                              {{
                                asc: (
                                  <HiOutlineSortAscending className='h-5 w-5 text-gray-600' />
                                ),
                                desc: (
                                  <HiOutlineSortDescending className='h-5 w-5 text-gray-600' />
                                ),
                              }[header.column.getIsSorted() as string] ??
                                (header.column.getCanSort() ? (
                                  <HiOutlineChevronUpDown className='h-5 w-5 text-gray-300' />
                                ) : null)}
                            </div>
                          </div>
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody className='divide-y divide-gray-200 bg-white text-gray-800'>
                {!isFetching
                  ? getRowModel().rows.map((row) => {
                      const idxRow = row?.original?.[keyId] || ''
                      const rows = row.getVisibleCells()
                      return (
                        <tr
                          key={row.id}
                          className='cursor-pointer hover:bg-slate-100 active:bg-slate-200'
                        >
                          {rows.map((cell, idx) => (
                            <td
                              role='presentation'
                              className={clsx(
                                'px-6',
                                hasAction && idx !== rows.length - 1 && 'py-4',
                                isDetail && 'py-4',
                                !hasAction && 'py-4'
                              )}
                              onClick={(event) => {
                                event.preventDefault()
                                storeCurrentParams()
                                return handleClickRow({ cell, row, id: idxRow })
                              }}
                              key={cell.id}
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </td>
                          ))}
                        </tr>
                      )
                    })
                  : skeletons.map((skeleton) => (
                      <tr key={skeleton}>
                        {Array.from(
                          { length: getAllColumns().length },
                          (x, i) => i
                        ).map((elm) => (
                          <td key={elm} className='animate-pulse px-6 py-1'>
                            <div className='my-4 h-full w-full bg-slate-200 p-2' />
                          </td>
                        ))}
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      {!(noDataFound || isError) && (
        <div className='flex flex-col items-center justify-center px-4 py-6 md:flex-row md:justify-between md:px-6'>
          {!isFetching ? (
            <>
              <div>
                Showing <b>{pageCount === 0 ? 0 : currentPage}</b> to{' '}
                <b>{Math.round(pageCount / perPage)}</b> of <b>{pageCount}</b>{' '}
                data
              </div>
              <nav aria-label='Pagination' className='mt-4 md:m-0'>
                <Pagination
                  className='pagination'
                  currentPage={currentPage}
                  totalCount={pageCount}
                  pageSize={perPage}
                  onPageChange={(val) => {
                    setCurrentPage(val)
                  }}
                />
              </nav>
            </>
          ) : (
            <>
              <div className='h-full w-full max-w-[220px] animate-pulse bg-slate-200 p-3' />
              <div className='h-full w-full max-w-[320px] animate-pulse bg-slate-200 p-4' />
            </>
          )}
        </div>
      )}
    </div>
  )
}

Table.displayName = 'Table'
