import { FormikHelpers } from 'formik'
import { useLocation, useNavigate } from 'react-router-dom'

import { storageKey } from '@constants/index'

import notification from '@stores/notification'

import { getErrorMessage, getMessage } from '@utils/apis'
import { storage } from '@utils/storage'

import { useLogin as useLoginService } from '../services'
import { LoginFormDTO } from '../types'

const useLogin = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const formValue: LoginFormDTO = {
    email: '',
    password: '',
  }

  const login = useLoginService()
  const handleSubmit = async (
    values: LoginFormDTO,
    formikHelpers: FormikHelpers<LoginFormDTO>
  ) => {
    await login
      .mutateAsync({
        data: values,
      })
      .then((res) => {
        notification.success(getMessage(res))
        const accessToken = res?.data?.data?.access_token
        if (accessToken) {
          storage.setItem(storageKey.authToken, `Bearer ${accessToken}`)
          navigate(location?.state?.redirect || '/dashboard')
        } else {
          notification.error('Terdapat kesalahan dalam akses token!')
        }
      })
      .catch((err) => {
        notification.error(getErrorMessage(err))
      })
      .finally(() => {
        formikHelpers.setSubmitting(false)
      })
  }

  return {
    formValue,
    handleSubmit,
  }
}

export default useLogin
