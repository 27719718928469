import clsx from 'clsx'
import React, { forwardRef } from 'react'

import { useFormControl } from '@forms/index'

import useInputClass from './Input.styles'
import { InputProps } from './Input.types'

export const Input = forwardRef<HTMLElement, InputProps>((props, ref) => {
  const {
    size = 'md',
    variant = 'outline',
    color = 'primary',
    as: Comp = 'input',
    'aria-label': ariaLabel,
    'aria-describedby': ariaDescribedby,
    className,
    type = 'text',
    id,
    ...rest
  } = props

  const { readOnly, disabled, invalid, required, ...formControl } =
    useFormControl(props)
  const classes = useInputClass({ size, variant, disabled, invalid })

  return (
    <Comp
      ref={ref}
      readOnly={readOnly}
      aria-readonly={readOnly}
      disabled={disabled}
      aria-disabled={disabled}
      aria-label={ariaLabel}
      aria-invalid={invalid}
      required={required}
      aria-required={required}
      aria-describedby={ariaDescribedby}
      data-color={color ? color : undefined}
      className={clsx(classes, className)}
      type={type}
      id={id || formControl.id}
      {...rest}
    />
  )
})

Input.displayName = 'Input'
