import React from 'react'

import { MistakeProps } from './Mistake.types'

export function Mistake(props: MistakeProps): JSX.Element {
  const { code, title, subtitle, children, ...rest } = props
  return (
    <div
      className='mistake-error mx-auto flex min-h-screen w-full max-w-[320px] flex-col items-center justify-center text-center leading-snug'
      {...rest}
    >
      {code && (
        <h1 className='-mb-8 text-8xl font-bold tracking-wider text-gray-200'>
          {code}
        </h1>
      )}
      {title && (
        <h2 className='text-2xl font-semibold text-gray-700'>{title}</h2>
      )}
      {subtitle && <p className='mt-2 text-gray-500'>{subtitle}</p>}
      {children}
    </div>
  )
}

export default Mistake
