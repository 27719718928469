import { HiOutlineRefresh } from 'react-icons/hi'

import { Button } from './Button'

export const ButtonRefresh: React.FC = () => {
  return (
    <Button
      type='button'
      variant='solid'
      color='primary'
      rightIcon={<HiOutlineRefresh className='ml-2' />}
      onClick={() => window.location.assign(window.location.origin)}
    >
      Refresh
    </Button>
  )
}
