import { z } from 'zod'

const loginForm = z.object({
  email: z
    .string({
      required_error: 'Email is harus di isi',
      invalid_type_error: 'Email harus berisi karakter',
    })
    .email({
      message: 'Email tidak valid',
    }),
  password: z.string({
    required_error: 'Password harus di isi',
    invalid_type_error: 'Password harus berisi karakter',
  }),
})

const forgotPasswordForm = z.object({
  email: z
    .string({
      required_error: 'Email is harus di isi',
      invalid_type_error: 'Email harus berisi karakter',
    })
    .email({
      message: 'Email tidak valid',
    }),
})

const resetPasswordForm = z
  .object({
    password: z.string({
      required_error: 'Password harus di isi',
      invalid_type_error: 'Password harus berisi karakter',
    }),
    password_confirmation: z.string({
      required_error: 'Ulangi Password harus di isi',
      invalid_type_error: 'Ulangi Password harus berisi karakter',
    }),
  })
  .refine((data) => data.password === data.password_confirmation, {
    message: 'Ulangi Password tidak sama dengan Password',
    path: ['password_confirmation'],
  })

export { forgotPasswordForm, loginForm, resetPasswordForm }
