import { HiBan, HiOutlineDocumentRemove } from 'react-icons/hi'

import { FilePreviewErrorType } from './FilePreview.types'

const FilePreviewError = (props: FilePreviewErrorType) => {
  const { type, message } = props
  return (
    <div className='flex w-full flex-col items-center justify-center self-stretch'>
      {type === 'not-found' ? (
        <HiOutlineDocumentRemove className='h-24 w-24 text-gray-300' />
      ) : (
        <HiBan className='h-24 w-24 text-gray-300' />
      )}
      <div className='mt-2 text-gray-400'>{message}</div>
    </div>
  )
}

export default FilePreviewError
