import clsx from 'clsx'
import React, { forwardRef } from 'react'

export const RequiredIndicator = forwardRef<
  HTMLSpanElement,
  React.HTMLAttributes<HTMLSpanElement>
>((props, ref) => {
  const { className, ...rest } = props
  const classes = clsx('required-indicator', className)

  return (
    <span ref={ref} className={classes} aria-hidden='true' {...rest}>
      *
    </span>
  )
})

RequiredIndicator.displayName = 'RequiredIndicator'
