import React from 'react'
import { Link } from 'react-router-dom'

import Mistake from './Mistake'
import { PageErrorProps } from './Mistake.types'

export const PageForbidden: React.FC<PageErrorProps> = (props) => {
  const { parentPath } = props

  return (
    <Mistake code='403' title='Forbidden'>
      <div className='mt-2'>
        You don&#39;t have permission to this page. Go to{' '}
        <Link to={parentPath || '.'} className='text-gray-700 underline'>
          home page
        </Link>
        .
      </div>
    </Mistake>
  )
}

export default PageForbidden
