import clsx from 'clsx'

import { removeNotification, useNotification } from '@stores/notification'

import { notificationPositionData } from './Notification.types'
import { NotificationMessage } from './NotificationMessage'

export function NotificationContainer(): JSX.Element {
  const [notification] = useNotification()
  const position = notificationPositionData[notification.position]
  const handleRemove = (id: string) => {
    removeNotification(id)
  }

  return (
    <div
      className={clsx(
        position.className,
        'fixed z-50 w-full md:max-w-sm',
        'pointer-events-none max-h-screen overflow-hidden p-4 md:p-4'
      )}
    >
      <div
        className={clsx(
          'fade pointer-events-none mr-8 w-full flex-1 flex-col justify-end'
        )}
      >
        {notification.data.map((val) => {
          return (
            <div
              key={val.id}
              className={clsx(
                'flex w-full py-1',
                'pointer-events-auto transform transition-all duration-300'
              )}
            >
              <NotificationMessage
                id={val.id}
                message={val.message}
                type={val.type}
                onRemove={handleRemove}
                lifetime={val.lifetime}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}
