import React from 'react'
import { IconType } from 'react-icons'

export interface ConfirmationButtonData {
  text?: string
  leftIcon?: JSX.Element
  rightIcon?: JSX.Element
}

export interface ConfirmationFunc {
  confirm(val?: ConfirmationConfirmProps): void
  alert(val?: ConfirmationAlertProps): void
}

export const ConfirmationType = {
  confirm: 'confirm',
  alert: 'alert',
} as const

export type ConfirmationType =
  (typeof ConfirmationType)[keyof typeof ConfirmationType]

export type ConfirmationTypeMap<T> = {
  [size in keyof typeof ConfirmationType]: T
}

export type ConfirmationAction = (
  close: () => void,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
) => void

export interface ConfirmationData {
  type?: ConfirmationType
  title?: string
  message?: string | JSX.Element | JSX.Element[]
  footer?: string | JSX.Element | JSX.Element[]
  icon?: ConfirmationIconType
  onConfirm?: ConfirmationAction
  onReject?: ConfirmationAction
  confirm?: ConfirmationButtonData
  reject?: ConfirmationButtonData
  dismiss?: ConfirmationDismissType
}

export type ConfirmationConfirmProps = ConfirmationData

export type ConfirmationAlertProps = Omit<
  ConfirmationData,
  'confirm' | 'onConfirm'
>

export type ConfirmationState = ConfirmationData | null

export type ConfirmationDismissType = {
  enabled?: boolean
  escapeKey?: boolean
  outsidePress?: boolean
  closeButton?: boolean
}

export interface ConfirmationIconTypeData {
  icon: IconType
  color: string
}

export const ConfirmationIconType = {
  error: 'error',
  warning: 'warning',
  success: 'success',
} as const

export type ConfirmationIconType =
  (typeof ConfirmationIconType)[keyof typeof ConfirmationIconType]

export type ConfirmationIconTypeMap<T> = {
  [type in keyof typeof ConfirmationIconType]: T
}
